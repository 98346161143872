import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Space, Col, Form, Select, Button, Row, InputNumber, Modal } from 'antd';
import { useSaveInstallItemMutation, useGetSapItemsQuery, useGetInstallKitsByIdQuery, executeSaveInstallItem } from '../../features/vehicleModels/sapApi';
import { useDispatch } from 'react-redux';
import { ResponsiveModal } from '../../features/responsive';

const ResourceInstallKitModal = props => {
	const history = useHistory();
	const { i18n, t } = useTranslation();
	const dispatch = useDispatch();
	const [saveInstallItem, response] = useSaveInstallItemMutation();
	const { data: installKit } = useGetInstallKitsByIdQuery({ id: props.installKitId }, { skip: props.installKitId === undefined });
	const { data: sapItems, isFetching } = useGetSapItemsQuery();
	const [sapItemList, setSapItemList] = useState([]);
	const [sapKitList, setSapKitList] = useState([]);
	const [form] = Form.useForm();
	const [isDirty, setIsDirty] = useState(false);

	useEffect(() => {
		if (sapItems) {
			setSapItemList(sapItems.filter(i => i.type === "kit").map((data) => {
				return { value: data.id, label: data.sapCode }
			}))
			setSapKitList(sapItems.filter(i => i.type === "item").map((data) => {
				return { value: data.id, label: data.sapCode }
			}))
		}
	}, [sapItems]);

	useEffect(() => {
		if (props.visible && form) {
			setIsDirty(false)
			if (props.installKitId) {
				form.setFieldsValue({ ...installKit })
			}
			else {
				form.resetFields();
			}
		}
	}, [props.visible, installKit]);


	const onAttach = (e) => {
		let payload = { ...e };

		if (props.installKitId) {
			payload = { ...{ id: props.installKitId }, ...payload };
		}

		executeSaveInstallItem(payload, saveInstallItem, dispatch).then(() => {
			if (props.onClose) {
				props.onClose();
			}
		})
	}

	return (
		<ResponsiveModal
			title={t("SapItem.InstallKitAssociation")}
			isModalOpen={props.visible}
			footer={null}
			onClose={props.onClose}>
			<Form
				form={form}
				onFinish={onAttach}
				layout={"vertical"}
				onValuesChange={() => { setIsDirty(true); }}
			>
				<Row>
					<Col xs={{ span: 24 }}>
						<Form.Item
							name="kitId"
							label={t("SapItem.SapKit")}
							rules={[{ required: true, message: t("ValidationErrors.SapKitRequired") }]}>
							<Select
								showSearch
								disabled={props.installKitId}
								filterOption={(input, option) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								}
								options={sapItemList}
							/>
						</Form.Item>
					</Col>
					<Col xs={{ span: 24 }}>
						<Form.Item
							name="partId"
							label={t("SapItem.SapItem")}
							rules={[{ required: true, message: t("ValidationErrors.SapItemRequired") }]}>
							<Select
								showSearch
								disabled={props.installKitId}
								filterOption={(input, option) =>
									(option?.label ?? '').toLowerCase().includes(input.toLowerCase())
								}
								options={sapKitList}
							/>
						</Form.Item>
					</Col>
					<Col xs={{ span: 24 }}>
						<Form.Item
							name="quantity"
							label={t("SapItem.Quantity")}
							rules={[{ required: true, message: t("ValidationErrors.QuantityRequired") }]}>
							<InputNumber style={{ width: '100%' }} />
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col xs={{ span: 24 }}>
						<Space>
							<Button
							    disabled={!isDirty}
								htmlType="submit"
								type="primary">
								{t("Common.SaveButton")}
							</Button>
							<Button onClick={props.onClose}>
								{t("Common.CancelButton")}
							</Button>
						</Space>
					</Col>
				</Row>
			</Form>
		</ResponsiveModal>
	)
}

export default ResourceInstallKitModal;