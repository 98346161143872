import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_PATH } from '../../config';

export const AuditApi = createApi({
  reducerPath: "AuditApi",
  refetchOnMountOrArgChange: true,
  baseQuery: fetchBaseQuery({ 
    baseUrl: API_PATH, 
    prepareHeaders: (headers, { getState, endpoint }) => {
      const { user } = getState();
      headers.set('Authorization', `Token token="${user?.current?.auth?.key}"`)
      return headers
  },
   }),
  tagTypes: ['audit'],
  endpoints: (builder) => ({
    getAudits: builder.query({
      query: (arg) => {
        if (Array.isArray(arg)) {
          return {
            url : `/audit?pruning=ALL&types=${arg.join(',')}`
          };  
        }
        const params = {
          pruning: 'ALL'
        };
        return {
          url : `/audit/${arg.entityType}/${arg.id}`,
          params
        };
      },
      providesTags: ['audit'],
    })
  })
})

export const { 
  useGetAuditsQuery
} = AuditApi



export const useGetAudits = (entityType, id) => {
  const { data, isFetching, refetch } = useGetAuditsQuery({entityType, id}, {pollingInterval: 1800000, skip:entityType === undefined});
  return { data, isFetching, refetch }
};

export const useGetAuditsForTypes = (entityTypes) => {
  if (!Array.isArray(entityTypes)) {
    throw new Error('The "types" parameter must be an array.');
  }
  const { data, isFetching, refetch } = useGetAuditsQuery(entityTypes, {pollingInterval: 1800000});
  return { data, isFetching, refetch }
};
