import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import style from './VehicleCompatibility.module.scss';
import { Table, Tag, Form, Select, Skeleton, Row, Col, Modal } from 'antd';
import { useGetSapItemsQuery } from '../../features/vehicleModels/sapApi';
import { Panel, PanelBody } from './../../components/panel/panel.js';
import { getSapItemDataColumns } from '../resource/ResourceListingHelper'
import {ResponsiveModal} from '../../features/responsive';

const VehicleCompatibilityResourceModal = ({kitDetail, isModalOpen, onClose}) => {
	const { i18n, t } = useTranslation();
	const { data: sapItems, isFetching } = useGetSapItemsQuery({embedChild:true});
	const [formattedData, setFormattedData] = useState([]);

	useEffect(()=>{
		if (sapItems && kitDetail && isModalOpen){
			let formattedSap = [];
			const sapData = sapItems.find(i=>i.id === kitDetail?.kit?.id);
			formattedSap.push({
				categories : sapData.categories,
				sapCode : sapData.sapCode,
				type : sapData.type,
				name : sapData.name,
				quantity : "-",
			});

			formattedSap = [...formattedSap, ...(sapData.childItem || []).map(data=>{
				return {
					categories : data.categories,
					sapCode : data.sapCode,
					type : data.type,
					name : data.name,
					quantity : data.quantity
				}
			})];
			
			setFormattedData(formattedSap);
		}
	}, [sapItems, kitDetail])
	
	return (
		<ResponsiveModal
			title={`${kitDetail?.vehicleModel?.make} ${kitDetail?.vehicleModel?.model} ${kitDetail?.vehicleModel?.yearOfManufacture} ${t("VehicleModel.InstallationKit")} #${kitDetail?.kit?.sapCode}`}
			isModalOpen={isModalOpen}
			footer={null}
			width={"50%"}
			style={{ minWidth: 450 }}
			bodyStyle={{padding:0}}
			onClose={onClose}>
			<Panel noPadding style={{ marginBottom: "15px" }}>
				<PanelBody>
					<Row className={style.fullWidthRow}>
						<Col xs={{ span: 24 }}>
							{formattedData.length === 0 ? <Skeleton active /> :
								<Table style={{ maxHeight:"70vh", overFlow:"auto"}} className={"sapTable"} pagination={false} columns={getSapItemDataColumns(false)} dataSource={formattedData} />
							}
						</Col>
					</Row>
				</PanelBody>
			</Panel>
		</ResponsiveModal>
	)
}

export default VehicleCompatibilityResourceModal;