import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { API_PATH } from '../../config';
import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { addToast } from "../../features/toast/toastsSlice"
import i18n from 'i18next';
import { parseErrorMessage } from '../../utils/strings';

export const SapApi = createApi({
  reducerPath: "SapApi",
  baseQuery: fetchBaseQuery({ 
    baseUrl: API_PATH, 
    prepareHeaders: (headers, { getState, endpoint }) => {
      const { user } = getState();
      headers.set('Authorization', `Token token="${user?.current?.auth?.key}"`)
      return headers
  },
   }),
  tagTypes: ['SapItems'],
  endpoints: (builder) => ({
    getSapItems: builder.query({
      query: (arg) => {
        let url = `/sapitems`;

        if (arg && arg.embedChild){
          url += "?embed=childItem";
        }

        return {
          url
        };
      },
      providesTags: ['SapItems'],
    }),
    getInstallKitsById: builder.query({
      query: (arg) => {
        const { id, type } = arg;
        return {
          url: `/installkits/${id}`
        };
      },
      providesTags: ['SapItems'],
    }),
    getSapItemById: builder.query({
      query: (arg) => {
        const { id, type } = arg;
        return {
          url: `/sapitems/${id}`
        };
      },
      providesTags: ['SapItems'],
    }),
    saveSapItem: builder.mutation({
      query: (payload) => {
        let url = '/sapitems';
        
        if (payload.id){
          url += "/"+payload.id;
        }

        return {
          url,
          method: payload.id ? 'PUT' : 'POST',
          body: payload,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      invalidatesTags: ['SapItems'],
    }),
    deleteSapItem: builder.mutation({
      query: (payload) => {
        let url = "/sapitems/"+payload.id;

        return {
          url,
          method:'DELETE',
          body: payload,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      invalidatesTags: ['SapItems'],
    }),
    saveInstallItem: builder.mutation({
      query: (payload) => {
        let url = '/installkits';
        
        if (payload.id){
          url += "/"+payload.id;
        }

        return {
          url,
          method: payload.id ? 'PUT' : 'POST',
          body: payload,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      invalidatesTags: ['SapItems'],
    }),
    deleteInstallItem: builder.mutation({
      query: (payload) => {
        let url = "/installkits/"+payload.id;
        return {
          url,
          method:'DELETE',
          body: payload,
          headers: {
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      invalidatesTags: ['SapItems'],
    })
  }),
})

export const { 
  useGetSapItemsQuery, 
  useGetSapItemByIdQuery, 
  useGetInstallKitsByIdQuery,
  useSaveSapItemMutation, 
  useDeleteSapItemMutation,
  useDeleteInstallItemMutation,
  useSaveInstallItemMutation,
  useGetSapCategoryQuery,
} = SapApi

export const useSapItemCategories = () => {
  const { i18n, t } = useTranslation();
  const { data: sapItems } = useGetSapItemsQuery();

  const categoriesArr = (sapItems || []).map((sap, index) => {
    return sap.categories;
  });

  const concatedCategories = categoriesArr.reduce((acc, val) => acc.concat(val), []);
  const uniqueCategory = concatedCategories.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });

  return useMemo(() => {
    return uniqueCategory.map((category, index) => {
      return { value: category, label: category, checked: true }
    });
  }, [sapItems]);
};

export const saveSap = (payload, saveSapItem, dispatch, sapType) => {
  return new Promise((resolve, reject) => {
    const key = "Sap" + sapType;

    dispatch(addToast({
      key,
      type: "loading",
      content: i18n.t("Common.Saving") + "...",
    }));

    saveSapItem(payload)
      .unwrap()
      .then(() => {
        resolve();

        dispatch(addToast({
          key,
          type: "success",
          content: i18n.t("SapItem.SapSaved"),
        }));
      })
      .catch((error) => {
        if (error) {
          dispatch(addToast({
            key,
            type: "error",
            content: parseErrorMessage(error.data)
          }));
        }
        reject(error);
      })
  });
}

export const executeDeleteInstallItem = (payload, deleteInstallItem, dispatch)=>{
  return new Promise((resolve, reject) => {
    const key = "InstallKit" + payload.id;

		dispatch(addToast({
			key,
			type: "loading",
			content: i18n.t("Common.Deleting") + "...",
		}));
		
		deleteInstallItem({id:payload.installKitId})
			.unwrap()
			.then(() => {
        resolve();
				dispatch(addToast({
					key,
					type: "success",
					content: i18n.t("SapItem.InstallKitDeleted"),
				}));
			})
			.catch((error) => {
				if (error) {
					dispatch(addToast({
						key,
						type: "error",
            content: parseErrorMessage(error.data)
					}));
				}
        reject(error);
			})
  });
}

export const executeSaveInstallItem = (payload, saveInstallItem, dispatch) => {
  return new Promise((resolve, reject) => {
    const key = "InstallKit" + payload.id;

    dispatch(addToast({
      key,
      type: "loading",
      content: i18n.t("Common.Saving") + "...",
    }));

    saveInstallItem(payload)
      .unwrap()
      .then(() => {
        dispatch(addToast({
          key,
          type: "success",
          content: i18n.t("SapItem.InstallKitSaved"),
        }));
        resolve();
      })
      .catch((error) => {
        if (error) {
          dispatch(addToast({
            key,
            type: "error",
            content: parseErrorMessage(error.data)
          }));
        }
        reject(error);
      })
  });
}

export const executeDeleteSapItem = (payload, deleteSapItem, dispatch) => {
  return new Promise((resolve, reject) => {
    const key = "Sap" + payload.id;

    dispatch(addToast({
      key,
      type: "loading",
      content: i18n.t("Common.Deleting") + "...",
    }));

    deleteSapItem({ id: payload.id })
      .unwrap()
      .then(() => {
        dispatch(addToast({
          key,
          type: "success",
          content: i18n.t("SapItem.SapDeleted"),
        }));
        resolve();
      })
      .catch((error) => {
        if (error) {
          dispatch(addToast({
            key,
            type: "error",
            content: parseErrorMessage(error.data)
          }));
        }
        reject(error);
      })
  });
}