import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Panel, PanelBody } from './../../components/panel/panel.js';
import { Table, Tag, Skeleton, Select, Space, Button, Row, Col, Input } from 'antd';
import style from './ResourceListing.module.scss';
import { exportFile } from '../../features/fileExport/ExcelFile';
import { getSapItemListingColumns, getExportOptionButtons, getExportFilename, getSapItemDataColumns, getSapChildItemDataColumns } from './ResourceListingHelper.js'
import { useGetSapItemsQuery, 
	useDeleteSapItemMutation, 
	useSapItemCategories, 
	useDeleteInstallItemMutation, 
	executeDeleteInstallItem,
	executeDeleteSapItem
} from '../../features/vehicleModels/sapApi';
import { RouteKey } from '../../config/page-key.js';
import { DownOutlined, UpOutlined, CloudDownloadOutlined, ApiOutlined, PlusCircleOutlined, AuditOutlined } from '@ant-design/icons';
import ResourceInstallKitModal from './ResourceInstallKitModal.js';
import { useDispatch } from 'react-redux';
import { setBackButton, setPageTitle } from '../../features/header/headerSlice';
import { ResponsiveButton } from '../../features/responsive';
import { Can } from "../../features/user/Can.js"
import { Permission } from '../../features/user/Permission.js';
import { Buttons } from '../../components/control/Buttons.js';
import { CsvLink } from '../../features/fileExport/CsvLink.js';

const ResourceListing = () => {
	const history = useHistory();
	const { i18n, t } = useTranslation();
	const dispatch = useDispatch();
	const { data: sapItems, isFetching } = useGetSapItemsQuery({embedChild:true});
	const sapCategories = useSapItemCategories();
	const [filteredData, setFilteredData] = useState(sapItems);
	const [csvData, setCsvData] = useState([]);
	const [filteredCategory, setFilteredCategory] = useState([]);
	const csvLinkRef = useRef(null);
	const [installKitId, setInstallKitId] = useState();
	const [associationModalVisible, setAssociationModalVisible] = useState(false);
	const [deleteSapItem] = useDeleteSapItemMutation();
	const [deleteInstallItem] = useDeleteInstallItemMutation();

	useEffect(() => {
		dispatch(setPageTitle(t("SapItem.Resource")));
		return () => {
			dispatch(setPageTitle(""));
		}
	}, [t]);

	useEffect(() => {
		csvData?.length && csvLinkRef?.current?.link?.click();
	}, [csvData]);
	  
	useEffect(() => {
		let filteredSapItem = sapItems || [];

		filteredSapItem = filteredSapItem.filter(i => i.categories.some(item => filteredCategory.includes(item)))

		setFilteredData(filteredSapItem);
	}, [sapItems, filteredCategory]);

	const extractData = useCallback(() => {
		let formattedData = [];
		(sapItems || []).forEach((data) => {
			formattedData.push({ name: data.name, sapCode: data.sapCode, type: data.type, quantity: "-" });
			const childArr = (data.childItem || []).map((child) => {
				return { name: child.name, sapCode: child.sapCode, type: child.type, quantity: child.quantity }
			});
			formattedData = [...formattedData, ...childArr];
		})

		return formattedData;
	}, [sapItems])

	const handleExportExcel = () => {
		exportFile(extractData().map((data) => {
			return {
				[t('SapItem.Code')]: data.sapCode,
				[t('SapItem.Quantity')]: data.quantity,
				[t('SapItem.Description')]: data.name,
				[t('SapItem.Type')]: data.type
			}
		}), {
			width: Array.apply(null, { length: getSapItemListingColumns().length }).map(_ => ({ wch: 25 })),
			title: t('SapItem.SapItem')
		}, t("Common.ExportWarning"))
	}

	const handleExportCSV = () => {
		setCsvData(
			extractData().map((data) => {
				return {
					[t('SapItem.Code')]: data.sapCode,
					[t('SapItem.Quantity')]: data.quantity,
					[t('SapItem.Description')]: `"${data.name}"`,
					[t('SapItem.Type')]: data.type
				}
			})
		);
	}

	const toggle = (value) =>{
		if (filteredCategory.includes(value)) {
			const index = filteredCategory.indexOf(value);
			const newFilteredCategory = [...filteredCategory];
			newFilteredCategory.splice(index, 1);
			setFilteredCategory(newFilteredCategory);
		}
		else{
			setFilteredCategory([...filteredCategory, ...[value]])
		}
	}

	useEffect(() => {
		const category = [];

		(sapCategories || []).forEach(data=>{
			category.push(data.value);
		})
		
		setFilteredCategory(category);
	}, [sapCategories]);

	const onDeleteRecord = (e) =>{
		executeDeleteSapItem(e, deleteSapItem, dispatch)
	}

	const onDeleteAssociation = (e) =>{
		executeDeleteInstallItem(e, deleteInstallItem, dispatch);
	}

	const onEditAssociation = (e) =>{
		setInstallKitId(e.installKitId);
		setAssociationModalVisible(true);
	}

	return (
		<div>
			<CsvLink data={csvData || []} ref={csvLinkRef} fileName={getExportFilename({
					entityName: t('SapItem.SapItem'),
					extension: ".csv"
				})} />
			<Panel noPadding newButtonAction={() => {
				return (
					<Space>
						<Can requiredPermission={[Permission.AUDIT]}>
							<ResponsiveButton onClick={() => {
								history.push(RouteKey.AUDIT_RESOURCE)
							}}
								title={t("Audits.Audits")}
								icon={<AuditOutlined />} />
						</Can>
						<Can requiredPermission={[Permission.COMMON_EDIT]}>
							<ResponsiveButton onClick={() => {
								setInstallKitId(undefined);
								setAssociationModalVisible(true);
							}}
								title={t("SapItem.AddNewInstallKit")}
								icon={<ApiOutlined />} />
						</Can>
						<Can requiredPermission={[Permission.COMMON_EDIT]}>
							<ResponsiveButton onClick={() => {
								history.push(RouteKey.SAP_FORM, { action: "ADD", type: "kit" })
							}}
								title={t("SapItem.AddNewSap")}
								icon={<PlusCircleOutlined />} />
						</Can>
						<Can requiredPermission={[Permission.EXPORT]}>
							<Buttons moreButtons={getExportOptionButtons({ handleExportExcel, handleExportCSV, isLoading: filteredData?.length === 0 })}
								noMargin={true}>
								<ResponsiveButton
									title={t("Common.Export")} disabled={filteredData?.length === 0}
									icon={<CloudDownloadOutlined />} />
							</Buttons>
						</Can>
					</Space>
				)
			}} style={{ marginBottom: "15px" }}>
				<PanelBody>
					<Row>
						<Col style={{padding:"20px"}}>
							<Space wrap>
								{
									(sapCategories || []).map((data) => {
										const isSelected = filteredCategory.includes(data.value);
										return (<Button style={{ background: isSelected ? "#DDFFD6" : "#fff", borderColor: isSelected ? "#78FF5C" : "#d9d9d9" }} shape="round" onClick={() => { toggle(data.value) }}>{data.label}</Button>)
									})
								}
							</Space>
						</Col>
					</Row>
					<Row className={style.fullWidthRow}>
						<Col xs={{ span: 24 }}>
							{isFetching ? <Skeleton active /> : 
								<Table
									tableLayout='fixed'
									pagination={{
										showSizeChanger: true
									}}
									className={"sapTable"} rowKey={(record) => record.id}
									expandIcon={(props) => {
										if (props.record.childItem && props.record.childItem?.length !== 0) {
											if (props.expanded) {
												return <a style={{ color: "#000" }} onClick={e => {
													props.onExpand(props.record, e);
												}}><UpOutlined /></a>
											} else {
												return <a style={{ color: "#000" }} onClick={e => {
													props.onExpand(props.record, e);
												}}><DownOutlined /></a>
											}
										}
									}}
									expandable={{
										expandedRowRender: (record) => <>
											<Table tableLayout='fixed' className='childSapTable' showHeader={false} pagination={false} columns={getSapChildItemDataColumns(onDeleteAssociation, onEditAssociation)} dataSource={record.childItem} />
										</>,
										rowExpandable: (record) => record.childItem && record.childItem?.length !== 0,
									}} columns={getSapItemDataColumns(onDeleteRecord)} dataSource={filteredData} />
							}
						</Col>
					</Row>
				</PanelBody>
			</Panel>
			<ResourceInstallKitModal 
			    installKitId={installKitId}
			    visible={associationModalVisible} 
				onClose={()=>setAssociationModalVisible(false)}/>
		</div>
	)
}

export default ResourceListing;