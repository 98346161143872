import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Menu, Dropdown, Space } from 'antd';
import headerStyles from './dropdown.module.scss';
import { useCurrentCompany } from '../../../features/company/companySlice';
import { useGetRegionsQuery } from '../../../features/regions/regionsApi';
import { useUserInfo } from '../../../features/user/userApi';

const HelpCenter = () =>{
	const { i18n, t } = useTranslation();
	const data = useUserInfo();
	const { data: regions } = useGetRegionsQuery(undefined, {skip:data.id === undefined});
	const company = useCurrentCompany();
	
	const feedbackUrl = useMemo(() => {
		let link;
	
		if (regions == null || company == null) {
		  return link;
		}
		const region = regions.find(r => r.code === company.country);

		if (region == null) {
		  return link;
		}
	
		try {
		  const configJson = JSON.parse(region.config);
		  if (configJson.vlt_feedback_url) {
			link = configJson.vlt_feedback_url;
		  }
		} 
		catch {
		  console.error('Region has no config or config json has format problem.');
		}
		return link;
	  }, [company, regions]);

	const renderHelpOption = () =>{
		return (
			<Menu>
				<Menu.Item onClick={() => { window.open(feedbackUrl, "_blank") }}>{t("Common.FeedbackForm")}</Menu.Item>
			</Menu>
		)
	}
	
	return (
		<Dropdown placement="bottom" overlay={renderHelpOption()} trigger={['click']}>
			<Link onClick={(e) => e.preventDefault()} className={`${headerStyles.Dropdown} ${headerStyles. navbarUser}`} style={{ "display": "flex" }}>
				<Space size={0} direction={"horizontal"}>
					<div className={headerStyles.ClickableDiv}>
						<div className={`${headerStyles.TextGreyDarker} ${headerStyles.ImageIcon}`}>
							<i style={{fontSize:20}} className="fa fa-question-circle"></i>
						</div>
					</div>
				</Space>
			</Link>
			</Dropdown>
	);
}

export default HelpCenter;
