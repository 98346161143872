import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Space, Form, Button, Modal, Col, Row, Select, Typography, Spin } from 'antd';
import { ResponsiveModal } from '../../features/responsive';
import { useDispatch } from 'react-redux';
import { InfoCircleFilled } from '@ant-design/icons';
import { useMergeVehicleModelMutation, executeMergeVehicleModel, useGetVehicleModelsQuery } from '../../features/vehicleModels/vehicleModelApi.js';
import style from './VehicleCompatibilityMergeModal.module.scss';
import { useCurrentCompany } from '../../features/company/companySlice';
import { useCompanies } from '../../features/company/companyApi';
import { confirmationModal } from '../../features/confirmationModal/confirmationModal';

const VehicleCompatibilityMergeModal = ({ isModalOpen, onClose, vehicleDetail }) => {
	const { i18n, t } = useTranslation();
	const [sourceVehicleModelOptions, setSourceVehicleModelOptions] = useState([]);
	const [targetVehicleModelOptions, setTargetVehicleModelOptions] = useState([]);
	const [selectedSource, setSelectedSource] = useState(vehicleDetail?.id);
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const currentCompany = useCurrentCompany();
	const companies = useCompanies();
	const [mergeVehicleModel, response] = useMergeVehicleModelMutation();
	const { data: vehicleModels } = useGetVehicleModelsQuery({ companyId: currentCompany?.id }, {pollingInterval: 3600000, skip: currentCompany?.id === undefined});

	const handleSourceSelected = (e) => {
		setSelectedSource(e)
	}

	useEffect(() => {
		if (vehicleModels && isModalOpen) {
			const rawSourceList = vehicleModels.filter(i => (i.groupId === undefined || i.groupId === null) && !i.isDummyInternal);
			setSourceVehicleModelOptions(rawSourceList.map(data => {
				return {
					value: data.id,
					label: `${data.make} ${data.model} ${data.yearOfManufacture} (${data.displaySource} - ${companies.find(i=>i.id === (data.company?.id || -1)).name})`
				}
			}))

			setSelectedSource(vehicleDetail?.id);
			form.setFieldValue("source", vehicleDetail?.id)
		}
	}, [vehicleModels, vehicleDetail?.id]);

	useEffect(() => {
		if (vehicleModels && selectedSource && isModalOpen) {
			const sourceVehicleModel = vehicleModels.find(i => i.id === selectedSource);

			const rawSourceList = vehicleModels.filter(i => i.id !== sourceVehicleModel.id &&
				i.source === sourceVehicleModel.source && (sourceVehicleModel.company?.id === i.company?.id || i?.company?.id === undefined));

			setTargetVehicleModelOptions(rawSourceList.map(data => {
				return {
					value: data.id,
					label: `${data.make} ${data.model} ${data.yearOfManufacture} (${data.source} - ${companies.find(i=>i.id === (data.company?.id || -1)).name})${data.isDummyInternal ? "*":""}`
				}
			}));

			form.setFieldValue("target", undefined);
		}
		else {
			setTargetVehicleModelOptions([]);
		}
	}, [selectedSource, vehicleModels]);

	const onFinish = (e) => {
		confirmationModal(
			t('VehicleModel.MergeVehicleModelModal.MergeConfirmationTitle'),
			t('VehicleModel.MergeVehicleModelModal.MergeConfirmationDescription'),
			t('Common.ConfirmButton'),
			t('Common.CancelButton'),
			() => {
				executeMergeVehicleModel(form.getFieldsValue(), mergeVehicleModel, dispatch).then(() => {
					if (onClose) {
						onClose();
					}
				});
			}
		  );
	}

	return (
		<>
			<ResponsiveModal
				onClose={onClose}
				isModalOpen={isModalOpen}
				width={"35%"}
				title={t("VehicleModel.MergeVehicleModelModal.MergeVehicleModel")}
				style={{ minWidth: 350 }}
				footer={null}
			>
				<Form form={form} layout="vertical" autoComplete="off" onFinish={onFinish}>
					<Row>
						<Col xs={{ span: 24 }} className={"mb-2"}>
							<Form.Item name="source" style={{ margin: 0 }}
								initialValue={vehicleDetail?.id}
								rules={[{ required: true, message: t("ValidationErrors.SourceRequired") }]}
								label={t("VehicleModel.MergeVehicleModelModal.SourceVehicleModel")}>
								<Select
									showSearch
									allowClear
									onChange={handleSourceSelected}
									maxTagCount={"responsive"}
									style={{ width: "100%" }}
									placeholder={t("VehicleModel.Model")}
									filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())}
									filterSort={(optionA, optionB) =>
										(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
									}
									options={sourceVehicleModelOptions}
								/>
							</Form.Item>
						</Col>
						<Col xs={{ span: 24 }} className={"mb-2"}>
							<Form.Item name="target" style={{ margin: 0 }}
								rules={[{ required: true, message: t("ValidationErrors.TargetRequired") }]}
								label={t("VehicleModel.MergeVehicleModelModal.TargetVehicleModel")}>
								<Select
									showSearch
									allowClear
									maxTagCount={"responsive"}
									style={{ width: "100%" }}
									placeholder={t("VehicleModel.Model")}
									filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input?.toLowerCase())}
									filterSort={(optionA, optionB) =>
										(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
									}
									options={targetVehicleModelOptions}
								/>
							</Form.Item>
						</Col>
					</Row>
					<Row style={{marginTop:20}}>
						<Col xs={{ span: 24 }}>
							<Space>
								<Button 
								    htmlType="submit"
									type="primary">
									{t("VehicleModel.Merge")}
								</Button>
								<Button onClick={onClose}>
									{t("Common.CancelButton")}
								</Button>
							</Space>
						</Col>
					</Row>
				</Form>
			</ResponsiveModal>
		</>
	)
}

export default VehicleCompatibilityMergeModal;