import React, { useState, useEffect, useCallback } from 'react';
import { Select, Badge } from 'antd';

import {
	CloseCircleOutlined
} from '@ant-design/icons';

const Multiselect = ({ options, onFilterChange, isLoading, placeholder, disabled = false, value, onChange}) => {
	const [currentSearchValue, setCurrentSearchValue] = useState('');
	
	const onValueChange = (e)=>{
		if (onFilterChange) {
			onFilterChange(e);
		}

		if (onChange){
			onChange(e)
		}
	}

	return (
		<Badge count={value.length} className='fullWidth' offset={[-5,0]}>
			<Select
				mode="multiple"
				maxTagCount="responsive"
				showSearch
				onChange={onValueChange}
				onClear={onValueChange}
				allowClear
				searchValue={currentSearchValue}
				dropdownMatchSelectWidth={250}
				value={value}
				clearIcon={<CloseCircleOutlined />}
				style={{ width: "100%" }}
				loading={isLoading}
				onSearch={(e)=>setCurrentSearchValue(e)}
				placeholder={placeholder}
				onBlur={()=>setCurrentSearchValue('')}
				filterOption={(input, option) => (option?.label ?? '')?.toLowerCase().includes(input?.toLowerCase())}
				filterSort={(optionA, optionB) =>
					(optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
				}
				options={options}
				disabled={disabled}
			/>
		</Badge>
	)
};

export default Multiselect;
