import React, {useEffect, useState} from 'react';
import { Route, withRouter, useLocation, Redirect } from 'react-router-dom';
import routes from '../../config/page-route.js';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import contentStyles from './content.module.scss';
import { useUserKey } from '../../features/user/ssoSlice';
import { RouteKey } from '../../config/page-key';
import { useGetRegionsQuery } from '../../features/regions/regionsApi';
import { useUserInfo } from '../../features/user/userApi';
import { Typography } from 'antd';
import IdleTimerContainer from '../../pages/idle/IdleTimerContainer.js';
import { useCan } from '../../features/user/Can.js';
const { Title, Text } = Typography;

function setTitle(path, routeArray) {
	var pageTitle;
	for (var i=0; i < routeArray.length; i++) {
		if (routeArray[i].path === path && i18n.t(routeArray[i].title)) {
			pageTitle = 'Teletrac Navman | ' + i18n.t(routeArray[i].title);
		}
	}
	document.title = (pageTitle) ? pageTitle : 'Teletrac Navman';
}

const Content = () =>{
	const userKey = useUserKey();
	const location = useLocation();
	const { i18n, t } = useTranslation();
	const data = useUserInfo();
	const can = useCan();
	const { data: regions } = useGetRegionsQuery(undefined, {skip:data.id === undefined});

	useEffect(() => {
		setTitle(location.pathname, routes);

		return ()=>{
			setTitle(location.pathname, routes);
		}
	}, [location.pathname, t]);
  
	const supportEmail = () => {
		const currentRegion = regions?.find(
		  region => region.locale.split('-')[0] === i18n.language
		);
		return typeof currentRegion === 'undefined'
		  ? 'tn360support-au@teletracnavman.com'
		  : currentRegion.supportEmail;
	  };

	const renderContent = (component, checkGuard) =>{
		if (userKey && data?.id !== undefined && data?.isUserAllowed === false && checkGuard) {
			return (
				<div className={contentStyles.noAccessContent}>
					<Title level={2}>You don't have access to this feature.</Title>
					<Text className={contentStyles.noAccessDescription}>
						If you think you should have access or this is an error please contact{' '}
						<a href={`mailto:${supportEmail(regions)}`}>{supportEmail(regions)}</a>
					</Text>
				</div>)
		}
		else {
			return component();
		}
	}

	return (
		<div className={contentStyles.content}>
			{userKey && data?.id !== undefined && data?.isUserAllowed && <IdleTimerContainer />}
			{
				routes.filter(i=> i.role === undefined || data.isSiteAdmin || can({ oneOfRole: i.role })).map((route, index) => (
					<Route
						key={index}
						path={route.path}
						exact={route.exact}
						component={!userKey?.length && route.guardRequired ? (() => <Redirect to={{
							pathname: RouteKey.LOGIN,
							state: { from: location }
						}} />) : () => renderContent(route.component, route.guardRequired)}
					/>
				))
			}
		</div>
	);
}

export default withRouter(Content);
