import i18n from 'i18next';
import { Buttons} from '../../components/control/Buttons'
import moment from 'moment';
import React from 'react';
import { Permission } from '../../features/user/Permission';

export const getDocumentDataColumns = (onView, onEdit, onDelete) => [
	{
		title: i18n.t('Document.DocName'),
		sorter: (a, b) => a.name.localeCompare(b.name),
		render: (e, r) => <div style={{textWrap:"balance"}}>{r.name}</div>,
		width: '20%'
	},
	{
		title: i18n.t('VehicleModel.Make'),
		render: (e, r) => <div>{Array.from(new Set(r.vehicleModels.map(v=>v.make))).join(", ")}</div>,
		width: '15%'
	},
	{
		title: i18n.t('VehicleModel.Model'),
		render: (e, r) => <div>{Array.from(new Set(r.vehicleModels.map(v=>v.model))).join(", ")}</div>,
		width: '15%'
	},
	{
		title: i18n.t('VehicleModel.Year'),
		render: (e, r) => <div>{Array.from(new Set(r.vehicleModels.map(v=>v.year))).join(", ")}</div>,
		width: '15%'
	},
	{
		title: i18n.t('VehicleModel.Source'),
		render: (e, r) => <div>{Array.from(new Set(r.vehicleModels.map(v=>v.source))).map((source) => <React.Fragment>{source}{<br />}</React.Fragment>)}</div>,
		width: '15%'
	},
	{
		title: i18n.t('Document.UpdatedAt'),
		render: (e, r) => <div>{moment(r.updatedAt).format("DD MMM YYYY")}</div>,
		responsive: ['md'],
		width: '10%'
	},
	{
		align:"center",
		render: (e, r) => <Buttons
		    noMargin={true}
			moreButtons={[
				{
					name: i18n.t('Common.View'),
					onClick: () => {
						if (onView)
							onView(r)
					}
				},
				{
					name: i18n.t('Common.Edit'),
					onClick: ()=>{
						if (onEdit)
						  onEdit(r)
					},
					permission: [Permission.COMMON_EDIT],
				},
				{
				name: i18n.t('Common.Delete'),
				onClick: ()=>{
					if (onDelete)
					  onDelete(r)
				},
				permission: [Permission.COMMON_EDIT],
				danger:true
			}]}
		/>
	}
];

export const getDocumentDataColumnsForInstallKit = (onView, onEdit, onDelete) => [
	{
		title: i18n.t('Document.DocName'),
		sorter: (a, b) => a.name.localeCompare(b.name),
		render: (e, r) => <div>{r.name}</div>
	},
	{
		title: i18n.t('VehicleModel.Kit #'),
		render: (e, r) => <div>{"-"}</div>
	},
	{
		title: i18n.t('Document.UpdatedAt'),
		render: (e, r) => <div>{moment(r.updatedAt).format("DD MMM YYYY")}</div>
	},
	{
		align:"center",
		render: (e, r) => <Buttons
		    noMargin={true}
			moreButtons={[
				{
					name: i18n.t('Common.View'),
					onClick: ()=>{
						if (onView)
						onView(r)
					}
				},
				{
					name: i18n.t('Common.Edit'),
					onClick: ()=>{
						if (onEdit)
						  onEdit(r)
					},
					permission: [Permission.COMMON_EDIT]
				},
				{
				name: i18n.t('Common.Delete'),
				onClick: ()=>{
					if (onDelete)
					  onDelete(r)
				},
				permission: [Permission.COMMON_EDIT],
				danger:true
			}]}
		/>
	}
];