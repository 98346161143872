import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Panel, PanelBody } from './../../components/panel/panel.js';
import { Table, Collapse, Skeleton, Space, Form, Button, Row, Col, Select, Spin, Typography, Tag, notification, Tooltip, List, Card } from 'antd';
import style from './VehicleCompatibility.module.scss';
import { addToast } from "../../features/toast/toastsSlice"
import FilterWrapper from "../../features/filterWrapper/FilterWrapper"

import { exportFile } from '../../features/fileExport/ExcelFile';
import { getVehicleModelDataColumns, getExportFilename, getExportOptionButtons } from './VehicleModelHelper.js'
import {
	useVehicleCompatibility, useVoteForScanRequestMutation, useUnVoteForScanRequestMutation, usePolls,
	getVehicleModelDocumentById
} from '../../features/vehicleModels/vehicleModelApi';
import { useCurrentCompany } from '../../features/company/companySlice.js';

import {
	CloudDownloadOutlined,
	SearchOutlined, 
	DownOutlined,
	UpOutlined,
	MinusCircleOutlined,
	CheckCircleOutlined,
	UndoOutlined,
	RightOutlined,
	EditOutlined,
	InfoOutlined,
	MergeCellsOutlined,
	AuditOutlined,
	ScanOutlined,
	PlusCircleOutlined
} from '@ant-design/icons';

import VehicleCompatibilityInfoModal from "./VehicleCompatibilityInfoModal.js";
import VehicleCompatibilityResourceModal from './VehicleCompatibilityResourceModal.js';
import VehicleCompatibilityDetailModal from './VehicleCompatibilityDetailModal.js';
import VehicleCompatibilitySearchModal from "./VehicleCompatibilitySearchModal.js";
import VehicleCompatibilityMergeModal from "./VehicleCompatibilityMergeModal.js";
import { useDispatch, useSelector } from 'react-redux';
import { setPageTitle } from '../../features/header/headerSlice';
import { ResponsiveButton, ResponsiveFilter } from '../../features/responsive';
import { RouteKey } from '../../config/page-key.js';
import { Can, useCan } from "../../features/user/Can.js";
import { Permission, Role } from '../../features/user/Permission.js';
import { useFuzzySearch, splitToParts } from '../../utils/fuzzySearch.js';
import Multiselect from '../../features/multiselect/Multiselect.js';
import { Buttons } from '../../components/control/Buttons.js';
import { CsvLink } from '../../features/fileExport/CsvLink.js';
import { 
	setSearchFilters, 
	setPageNumber 
 } from "../../features/vehicleModels/vehicleModelFilterSlice.js"
import { fetchDocument } from '../../features/document/documentApi';
import { useUserKey } from '../../features/user/ssoSlice';
import { isValidHttpUrl } from '../../utils/strings.js';
import { KitType } from './constant.js';
import { useMediaQuery } from 'react-responsive';
import { useCompanies } from '../../features/company/companyApi.js';
import { VehicleMakeModelYearFormModal } from './VehicleMakeModelYearFormModal.js';

const { Title, Text, Paragraph } = Typography;
const { Panel: CollapsePanel } = Collapse;

const VehicleCompatibility = () => {
	const history = useHistory();
	const csvLinkRef = useRef(null);
	const { i18n, t } = useTranslation();
	const currentCompany = useCurrentCompany();
	const dispatch = useDispatch();
	const [csvData, setCsvData] = useState([]);
	const userKey = useUserKey();
	const can = useCan();
	const isMobile =useMediaQuery({ maxWidth: 767 })

	const [showCsvSearch, setShowCsvSearch] = useState(false);
	const [infoModelOpen, setInfoModalOpen] = useState(false);
	const [vehicleCompatibilityDetailModal, setVehicleCompatibilityDetailModal] = useState(false);
	const [vehicleMergeModal, setVehicleMergeModal] = useState(false);
	const [newVehicleModelModal, setNewVehicleModelModal] = useState(false);
	const [selectedKit, setSelectedKit] = useState();
	const [selectedVehicleCompatibility, setSelectedVehicleCompatibility] = useState();
	const [filterForm] = Form.useForm();
	const { vehicleCompatibility, allDeviceTypes, isFetching: isVehicleCompatibilityLoading } = useVehicleCompatibility(currentCompany.id)
	const [filterVehicleCompatibility, setFilterVehicleCompatibility] = useState(vehicleCompatibility);
	const keys = ['make', 'model', 'yearOfManufacture', 'source', 'fullText'];
	const currentPage = useSelector(state => state.vehicleModelFilter.pageNumber);
	const searchObj = useSelector(state => state.vehicleModelFilter.searchFilter);
	const companies = useCompanies();
	const [showAdvanceSearch, setShowAdvanceSearch] = useState(searchObj !== null && searchObj !== undefined);
	const [voteForScanRequest] = useVoteForScanRequestMutation();
	const [unVoteForScanRequest] = useUnVoteForScanRequestMutation();
	const [votes, setVotes] = useState(new Set());
	const [unvotablePolls, setUnvotablePolls] = useState(new Map());
	const {polls, history: userVoteHistory, isFetching} = usePolls();

	useEffect(() => {
		if (!isFetching) {
			const latestVotes = new Set();
			userVoteHistory.forEach(obj => {
				const key = obj['vehicleModelId'];
				latestVotes.add(key);
			});
			setVotes(latestVotes);
		}
	}, [isFetching, userVoteHistory]);

	useEffect(() => {
		if (!isFetching) {
			const latestPolls = new Map();
			polls.filter(poll => poll.status !== 'IN_REVIEW').forEach(poll => {
				latestPolls.set(poll.vehicleModel.id, poll);
			});
			setUnvotablePolls(latestPolls);
		}
	}, [isFetching, polls]);

	const handlePageChange = (page) => {
		dispatch(setPageNumber(page));
	};
	
	const { performSearch, result : searchResult } = useFuzzySearch((vehicleCompatibility || []), keys);

	const updateRowCount = (recordCount) => {
		setTimeout(() => {
			//TODO this is bad but i couldn't find any better code, will revisit
			let expanderHeader = document.querySelector('.ant-table-thead .ant-table-row-expand-icon-cell');

			if (expanderHeader) {
				expanderHeader.innerHTML = `<div style='text-align:center; padding:0px 15px;font-size:12px;color:#9B9B9B'>${recordCount} ${t("Common.RecordFound")}</div>`;
			}
		}, 500);
	}

	useEffect(() => {
		dispatch(setPageTitle(t("VehicleModel.VehicleLookupTool")));

		return () => {
			dispatch(setPageTitle(""));
		}
	}, [t]);

	useEffect(() => {
		let filteredData = vehicleCompatibility || [];
		if (searchObj) {
			if (searchObj.any && searchObj.any.length !== 0){
				filteredData = performSearch(searchObj.any, keys);
			}
			else{
				performSearch([], keys)
			}
			
			if (searchObj.make && searchObj.make.length !== 0) {
				filteredData = filteredData.filter(i => searchObj.make.includes(i.make?.trim()));
			}

			if (searchObj.model && searchObj.model.length !== 0) {
				filteredData = filteredData.filter(i => searchObj.model.includes(i.model?.trim()));
			}

			if (searchObj.year && searchObj.year.length !== 0) {
				filteredData = filteredData.filter(i => searchObj.year.includes(i.yearOfManufacture));
			}

			if (searchObj.source && searchObj.source.length !== 0) {
				filteredData = filteredData.filter(i => searchObj.source.includes(i.source.trim()));
			}

			if (searchObj.busType && searchObj.busType.length !== 0) {
				filteredData = filteredData.filter(i => i.busType && searchObj.busType.some(item => item?.toLowerCase()=== i.busType?.toLowerCase()));
			}

			if (searchObj.deviceType && searchObj.deviceType.length !== 0) {
				filteredData = filteredData.filter(i => searchObj.deviceType.includes(parseInt(i.deviceTypeId)));
			}

			if (searchObj.company && searchObj.company.length !== 0) {
				filteredData = filteredData.filter(i => searchObj.company.includes(parseInt(i.company?.id || -1)));
			}

			if (searchObj.pid && searchObj.pid.length !== 0) {
				filteredData = filteredData.filter(i => (i.supportedPid || []).length !== 0 &&
					searchObj.pid.every(pid => (i.supportedPid || []).find(k => k.description === pid)));
			}
		}
		
		setFilterVehicleCompatibility(filteredData);
		updateRowCount(filteredData.length);
	}, [searchObj, vehicleCompatibility, t]);

	const onFilterChange = value => {
		filterForm.submit();
	};

	const fullValues = useMemo(() => {
		const yearValue = filterForm?.getFieldValue("year") || [];
		const makeValue = filterForm?.getFieldValue("make") || [];
		const modelValue = filterForm?.getFieldValue("model") || [];
		const sourceValue = filterForm?.getFieldValue("source") || [];
		const busTypeValue = filterForm?.getFieldValue("busType") || [];
		const deviceTypeValue = filterForm?.getFieldValue("deviceType") || [];
		const vinRegLicValue = filterForm?.getFieldValue("vinRegLic") || [];
		const pidValue = filterForm?.getFieldValue("pid") || [];
		const companyValue = filterForm?.getFieldValue("company") || [];
		
		let makeList = [];
		let modelList = [];
		let yearList = [];
		let sourceList = [];
		let busTypeList = [];
		let deviceTypeList = [];
		let pidList = [];
		let companyList = [];

		let filteredData = vehicleCompatibility || [];

		if (vinRegLicValue.length !== 0 && searchResult) {
			filteredData = searchResult;
		}

		filteredData.map(makeModelYear => {
			const makeMatch= (makeValue?.length === 0 || makeValue?.includes(makeModelYear.make));
			const modelMatch=(modelValue?.length === 0 || modelValue?.includes(makeModelYear.model));
			const yearMatch= (yearValue?.length === 0 || yearValue?.includes(makeModelYear.yearOfManufacture));
			const sourceMatch=(sourceValue?.length === 0 || sourceValue?.includes(makeModelYear.source));
			const busTypeMatch=(busTypeValue?.length === 0 || busTypeValue?.includes(makeModelYear.busType));
			const deviceTypeMatch=(deviceTypeValue?.length === 0 || deviceTypeValue?.includes(makeModelYear.deviceTypeId));
			const companyMatch=(companyValue?.length === 0 || companyValue?.includes(makeModelYear.company?.id || -1));
			const pidMatch = pidValue?.length ===0 || ((makeModelYear.supportedPid|| []).length !== 0 && (pidValue || []).every(pid => (makeModelYear.supportedPid || []).find(k => k.description === pid)));

			if (makeMatch && yearMatch && sourceMatch && busTypeMatch && deviceTypeMatch && pidMatch && companyMatch) {
				modelList.push(makeModelYear.model);
			}

			if (modelMatch && yearMatch && sourceMatch && busTypeMatch && deviceTypeMatch && pidMatch && companyMatch) {
				makeList.push(makeModelYear.make);
			}

			if (modelMatch && makeMatch && sourceMatch && busTypeMatch && deviceTypeMatch && pidMatch && companyMatch && makeModelYear.yearOfManufacture) {
				yearList.push(makeModelYear.yearOfManufacture);
			}

			if (modelMatch && makeMatch && yearMatch  && busTypeMatch && deviceTypeMatch && pidMatch && companyMatch && makeModelYear.source) {
				sourceList.push(makeModelYear.source);
			}

			if (modelMatch && makeMatch && yearMatch && sourceMatch && deviceTypeMatch && pidMatch && companyMatch && makeModelYear.busType) {
				busTypeList.push(makeModelYear.busType);
			}

			if (modelMatch && makeMatch && yearMatch && sourceMatch && busTypeMatch && pidMatch && companyMatch && makeModelYear.deviceTypeId) {
				deviceTypeList.push(makeModelYear.deviceTypeId);
			}

			if (makeMatch && modelMatch && yearMatch && sourceMatch && busTypeMatch && deviceTypeMatch && companyMatch && makeModelYear.supportedPid) {
				pidList.push(makeModelYear.supportedPid);
			}

			if (modelMatch && makeMatch && yearMatch && sourceMatch && busTypeMatch && pidMatch && deviceTypeMatch) {
				companyList.push(makeModelYear.company?.id || -1);
			}
		});

		yearList =  Array.from(new Set(yearList.map(i=>i))).map(i=>{
			return { label: i?.toString(), value: i }
		});

		modelList =  Array.from(new Set(modelList.map(i=>i?.trim()))).map(i=>{
			return { label: i, value: i }
		})

		makeList  =Array.from(new Set(makeList.map(i=>i?.trim()))).map(i=>{
			return { label: i, value: i }
		});

		sourceList = Array.from(new Set(sourceList.map(i=>i?.toLowerCase().trim()))).map(i=>{
			return { label: i, value: i }
		});

		busTypeList = Array.from(new Set(busTypeList.map(i=>i))).map(i=>{
			return { label: i, value: i }
		});

		deviceTypeList = Array.from(new Set(deviceTypeList.map(i=>i))).map(i=>{
			return { label: allDeviceTypes.find(k=>k.id === i).name, value: i }
		});

		companyList = Array.from(new Set(companyList.map(i=>i))).map(i=>{
			return { label: companies.find(k=>k.id === i).name, value: i }
		});

		pidList = Object.values(
			pidList.flat().reduce((map, obj) => {
				if (!map[obj.id]) {
					map[obj.id] = obj;
				}
				return map;
			}, {})
		);

		const parsedPid = pidList.map(data => {
			return {
				category: data.category.toUpperCase() === "UNKNOWN" ? "Others" : data.category,
				description: data.description
			}
		})

		const uniquePid = parsedPid.filter((obj, index, self) =>
			index === self.findIndex((o) => (
				o.category === obj.category && o.description === obj.description
			))
		);

		const groupedPid = (uniquePid || []).sort((a, b) => {
			if (a.category.toUpperCase() === "OTHERS") return 1;
			if (b.category.toUpperCase() === "OTHERS") return -1;
			return 0;
		}).reduce((acc, obj) => {
			const { category } = obj;
			if (!acc[category]) {
				acc[category] = [];
			}
			acc[category].push(obj);
			return acc;
		}, {});

		return {
			make : makeList,
			model : modelList,
			year : yearList,
			source : sourceList,
			busType:busTypeList,
			deviceType : deviceTypeList,
			company : companyList,
			pidList: groupedPid
		}
	}, [vehicleCompatibility, 
		searchResult, 
		filterForm?.getFieldValue("pid"), 
		filterForm?.getFieldValue("year"), 
		filterForm?.getFieldValue("make"), 
		filterForm?.getFieldValue("model"), 
		filterForm?.getFieldValue("source"), 
		filterForm?.getFieldValue("deviceType"), 
		filterForm?.getFieldValue("busType"), 
		filterForm?.getFieldValue("vinRegLic")]);

	const handleExportExcel = () => {
		if (isIOSMobile() && (filterVehicleCompatibility || []).length >= 10000) {
			notification.error({
				placement : 'topRight',
				description: t("ValidationErrors.DataSizeTooLargeError", {number:10000}),
				style : {background:"#fff2f0"}
			  })
		    return;
		}

		const extractedPids = [...new Set(filterVehicleCompatibility.flatMap(obj => obj.supportedPid))];
		const pidCategories = Array.from(new Set(extractedPids.map(i=>i.category.toUpperCase() === "UNKNOWN" ? "Others" : i.category).sort((a, b) => {
			if (a.toUpperCase() === "OTHERS") return 1;
			if (b.toUpperCase() === "OTHERS") return -1;
			return 0;
		})))

		const parsedData = filterVehicleCompatibility.map((data) => {
			let rowObj = {
				[t('VehicleModel.Make')]: data.make,
				[t('VehicleModel.Model')]: data.model,
				[t('VehicleModel.Year')]: data.yearOfManufacture,
				[t('VehicleModel.Source')]: data.source,
				[t('VehicleModel.DbcKey')]: data.dbcFileKey || "-",
				[t('VehicleModel.Vendor')]: data.deviceType || "-",
				[t('VehicleModel.Supported')]: data.isSupported ? t("VehicleModel.Yes") : (data.isSupported === undefined ? t("VehicleModel.Unknown") : t("VehicleModel.No")),
				[t('VehicleModel.Verified')]: data.verified ? t("VehicleModel.Yes") : t("VehicleModel.No")
			}

			if (can({ oneOfRole: [Role.ADMIN, Role.SITE_ADMIN] })) {
				rowObj = {
					[t('VehicleModel.Make')]: data.make,
					[t('VehicleModel.Model')]: data.model,
					[t('VehicleModel.Year')]: data.yearOfManufacture,
					[t('VehicleModel.Source')]: data.source,
					[t('VehicleModel.DbcKey')]: data.dbcFileKey || "-",
					[t('VehicleModel.Universal Kit #')]: Array.from(new Set((data[KitType.UNIVERSAL_KIT] || []).map(i=>i.sapCode))).join('\n'),
					[t('VehicleModel.Kit #')]: Array.from(new Set((data[KitType.KIT] || []).map(i=>i.sapCode))).join('\n'),
					[t('VehicleModel.Y Cable (Included with Kit)')]: Array.from(new Set((data[KitType.Y_CABLE_KIT] || []).map(i=>i.sapCode))).join('\n'),
					[t('VehicleModel.Additional Parts')]: Array.from(new Set((data[KitType.ADDITIONAL] || []).map(i=>i.sapCode))).join('\n'),
					[t('VehicleModel.Vendor')]: data.deviceType || "-",
					[t('VehicleModel.Supported')]: data.isSupported ? t("VehicleModel.Yes") : (data.isSupported === undefined ? t("VehicleModel.Unknown") : t("VehicleModel.No")),
					[t('VehicleModel.Verified')]: data.verified ? t("VehicleModel.Yes") : t("VehicleModel.No")
				}
			}

			pidCategories.forEach(categoryName=>{
				const pids = (data.grouppedPid || {})[categoryName];
				if (pids && pids.length !== 0) {
					rowObj[categoryName] = Array.from(pids).join('\n');
				}
				else {
					rowObj[categoryName] = "-"
				}
			})

			return rowObj;
		});
		
		exportFile(parsedData, {
			width: Array.apply(null, { length: Object.keys(parsedData[0]).length }).map(_ => ({ wch: 25 })),
			title: t('VehicleModel.ExportFileName')
		}, t("Common.ExportWarning"))
	}

	const onFinish = (e) => {
		dispatch(setSearchFilters({ ...searchObj, 
			...{ make: e.make, 
				 pid: e.pid, 
				 model: e.model, 
				 year: e.year, 
				 source: e.source, 
				 company: e.company, 
				 deviceType: e.deviceType, 
				 busType: e.busType, 
				 any: e.vinRegLic 
				} }));
		dispatch(setPageNumber(1));
	};

	const handleTagChange = (values) => {
		if (values.length === 2 && !values[0].toLowerCase().startsWith("or ") && values[1].toLowerCase().startsWith("or ")) {
			values[0] = 'or ' + values[0];
		}
		const sortedTags = values.filter((value) => value.toLowerCase().startsWith("or "));
		sortedTags.push(...values.filter((value) => !value.toLowerCase().startsWith("or ")));
		filterForm.setFieldsValue({ ...filterForm.getFieldValue(), vinRegLic: sortedTags });
		filterForm.submit();
	};

	const isIOSMobile = () => {
		return ([
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod'
		].includes(navigator.userAgentData?.platform || navigator.platform)
			|| (navigator.userAgent.includes("Mac") && "ontouchend" in document)) && isMobile;
	}

	const renderTag = (props) => {
		const { value, closable, onClose } = props;

		const isYellowTag = value.toLowerCase().startsWith('or ');
		const label = isYellowTag ? value.substring(3) : value;
		const parts = splitToParts(label);
		const pattern = /^(\w+):\W?["]?([^"]+)["]?$/;

		const tags = parts.map((value) => {
			const matches = value.match(pattern);
			if (matches) {
				const key = matches[1];
				const text = matches[2];
				return {key, text, style: style.fieldMatch};
			} else {
				return {key: null, text: value};
			}
		});
		return (
			<Tag
				color={isYellowTag ? 'yellow' : 'green'}
				closable={closable}
				onClose={onClose}
				style={{ marginRight: 3 }}
			>
				{
					tags.map((tag) => tag.key ? (<span className={tag.style}>{tag.text}</span>):<>{tag.text} </>)
				}
			</Tag>
		);
	};

	const onViewKit = (kit, vehicleModel) => {
		setSelectedKit({ kit, vehicleModel })
	}

	const scanRequestRender = (record) => {
		if (record.source === 'internal' && !record.isDummyInternal) {
			if (unvotablePolls.has(record.id)) {
				return <Tooltip title={unvotablePolls.get(record.id).comment}>
					<ResponsiveButton type="link" style={{color: '#A0A0A0', cursor: 'not-allowed'}} 
						title={t("VehicleModel.PollClosed", { status: t('VehicleModel.RequestStatus.' + unvotablePolls.get(record.id).status)})}
						icon={<ScanOutlined />}/>
				</Tooltip>
			} else if (votes.has(record.id)) {
				return <ResponsiveButton type="link" onClick={() => {
					unVoteForScanRequest({id: record.id});
					votes.delete(record.id);
					dispatch(
						addToast({
							title:i18n.t("VehicleModel.ScanRequest"),
							type:"success",
							content: <Trans
								i18nKey={'VehicleModel.RequestCancelled'}
								components={{ primary: <Text type="success" strong />, secondary: <Text strong /> }}
							/>,
						})
					);

				}} title={t("VehicleModel.CancelRequest")} icon={<ScanOutlined />} />
			} else {
				return <ResponsiveButton type="link" onClick={() => {
					voteForScanRequest({id: record.id});
					votes.add(record.id);
					dispatch(
						addToast({
							title:i18n.t("VehicleModel.ScanRequest"),
							type:"success",
							content: <Trans
								i18nKey={'VehicleModel.ScanRequested'}
								components={{ primary: <Text type="success" strong />, secondary: <Text strong /> }}
							/>,
						})
					);

				}} title={t("VehicleModel.RequestScan")} icon={<ScanOutlined />} />
			}
		}
	}

	const compatibilityRender = (data) => {
		const groupedPidData = (data?.supportedPid || []).reduce((acc, item) => {
			const key = `${item.canId}-${item.dbcKey || ""}`;

			if (!acc[key]) {
				acc[key] = [];
			}

			acc[key].push(item);

			return acc;
		}, {});

		const canDataArr = [];
		Object.keys(groupedPidData).map((d)=>{
			const [canId, dbcKey] = d.split('-');
			const pids = groupedPidData[d].reduce((acc, pid) => {
				const key = pid.deviceModelName;

				if (!acc.has(key)) {
					acc.set(key, []);
				}

				acc.get(key).push(pid);
				return acc;
			}, new Map());
			let canData = {
				pids: pids,
				canId: canId,
				dbcKey: dbcKey
			}

			if (data.documentInfo?.installManualInfo[`can${canId}Input`]) {
				canData.canInput = data.documentInfo?.installManualInfo[`can${canId}Input`];
			}

			canDataArr.push(canData);
		});

		if (data.documentId && data.documentInfo === undefined) {
			return (
				<Col xs={{ span: 24 }} style={{ paddingTop:10, paddingLeft:15 }}>
					<Spin />
				</Col>
			)
		};

		const rowColCount = canDataArr.length > 1 ? 2 : 1;
		return (
			<>
				{canDataArr.length !== 0 ?
					<>
						<Col xs={{ span: 24 }}>
							<List
								itemLayout="horizontal"
								size="small"
								grid={{ 
									gutter: 16,  
									xs: 1,
									sm: 1,
									md: 1,
									lg: rowColCount,
									xl: rowColCount,
									xxl: rowColCount
								}}
								dataSource={canDataArr}
								renderItem={(item, index) => {
									return (
										<List.Item>
											<List.Item.Meta
												description={
													<Row>
														<Col xs={{ span: 24 }}>
															<Title level={5}>{`${t("VehicleModel.CanBusDetail")} ${item.canId}${item.dbcKey ? ' - ' + item.dbcKey : ''}`}</Title>
														</Col>
														{
															(item.pids?.size > 0) ?
																([...item.pids.keys()].map(key => {
																	const pids = item.pids.get(key) || [];
																	return (
																		<>
																		<Col xs={{ span: 24 }}><b style={{marginLeft: '20px'}}>{key}</b></Col>
																		<Space wrap style={{marginLeft: '20px', marginBottom: '8px'}}>
																		{
																			(pids.map(pid => {
																				return (
																				<Button shape="round" className={style.compatibilityInfoBtn}
																					icon={pid.category.toUpperCase() === "UNKNOWN" ? <MinusCircleOutlined style={{ color: "grey" }} /> : <CheckCircleOutlined style={{ color: "green" }} />}>
																					{pid.description}
																				</Button>
																				)
																			}))
																		}
																		</Space>
																		</>
																	)
																})) : <Col xs={{ span: 24 }}>-</Col>
														}
														{item.canInput &&
															<>
																<Col xs={{ span: 24 }}>
																	<Title level={5}>{`${t("VehicleModel.CanInput",{index:item.canId})}`}</Title>
																</Col>
																<Col xs={{ span: 24 }}>
																	<Paragraph>
																		<ul>
																			<li><Text>{item.canInput.canHigh}</Text></li>
																			<li><Text>{item.canInput.canLow}</Text></li>
																		</ul>
																	</Paragraph>
															</Col>
															{
																index === canDataArr.length -1 && data.documentInfo?.installManualInfo?.additionalInformation?.info &&
																<Col xs={{ span: 24 }} style={{paddingLeft:index ===0 ? 15: 0}}>
																	<Text strong italic>{data.documentInfo?.installManualInfo?.additionalInformation?.info}</Text>
																</Col>
															}
															</>
														}
													</Row>
												}
											/>
										</List.Item>
									)
								}}
							/>
						</Col>
					</> : 
					<>
						<Col xs={{ span: 24 }} style={{paddingLeft:15}}>
							<Row className='mb-2'>
								<Col xs={{ span: 24 }}>
									<Title level={5}>{t("VehicleModel.CanBusMeasurement")}</Title>
								</Col>
								<Space wrap>-</Space>
							</Row>
						</Col>
					</>}
			</>
		)
	}

	const InstallationSupportRender = (data) => {
		let supportedKitList = data.supportedKit || [];
		supportedKitList = supportedKitList.reduce((acc, current) => {
			const x = acc.find((item) => item.id === current.id);
			if (!x) {
				return acc.concat([current]);
			} else {
				return acc;
			}
		}, []);

		return (
			<Col xs={{ span: 24 }} lg={{ span: 6 }} xl={{ span: 4 }}>
				<Row>
					<Col xs={{ span: 24 }} style={{ paddingLeft: "15px" }}>
						<Title level={5}>{t("VehicleModel.InstallationKit")}</Title>
					</Col>
					{data.documentId &&
						<Col xs={{ span: 24 }}>
							<Button onClick={() => {
								var win = window.open();
								fetchDocument(data.documentId, dispatch, userKey).then(e => {
									if (isValidHttpUrl(e)){
										win.location = e;
									}
									else{
										win.document.write(e);
									}
								}).catch(e => win.close())
							}} type="link" className={style.infoBtnIcon} icon={<InfoOutlined onClick={() => { setInfoModalOpen(true) }} style={{
								backgroundColor: "#1677ff",
								fontSize: 10,
								borderRadius: 25,
								padding: 2,
								color: "#fff"
							}} />}>
								{t("VehicleModel.InstallationSupport")}
							</Button>
						</Col>
					}
					{can({ oneOfRole: [Role.ADMIN, Role.SITE_ADMIN] }) && 
						(supportedKitList.filter(i => i.sapType === "kit").length !== 0) ?
							supportedKitList.filter(i => i.sapType === "kit").map((kit) => {
								return (
									<Col xs={{ span: 24 }}>
										<Button onClick={() => { onViewKit(kit, data) }} type="link" className={style.infoBtnIcon} icon={<InfoOutlined onClick={() => { setInfoModalOpen(true) }} style={{
											backgroundColor: "#1677ff",
											fontSize: 10,
											borderRadius: 25,
											padding: 2,
											color: "#fff"
										}} />}>

											{t("VehicleModel.Kit #") + kit.sapCode}
										</Button>
									</Col>
								);
							})
							: (data.documentId === undefined ? <Col xs={{ span: 24 }} style={{ paddingLeft: "15px" }}>-</Col> : null)
					}
				</Row>
			</Col>
		)
	}

	useEffect(() => {
		csvData?.length && csvLinkRef?.current?.link?.click();
	}, [csvData]);

	const handleExportCSV = () => {
		const extractedPids = [...new Set(filterVehicleCompatibility.flatMap(obj => obj.supportedPid))];
		const pidCategories = Array.from(new Set(extractedPids.map(i=>i.category.toUpperCase() === "UNKNOWN" ? "Others" : i.category).sort((a, b) => {
			if (a.toUpperCase() === "OTHERS") return 1;
			if (b.toUpperCase() === "OTHERS") return -1;
			return 0;
		})))
		
		setCsvData(
			filterVehicleCompatibility.map((data) => {
				let parsedData = {
					[t('VehicleModel.Make')]: `"${data.make}"`,
					[t('VehicleModel.Model')]: `"${data.model}"`,
					[t('VehicleModel.Year')]: data.yearOfManufacture,
					[t('VehicleModel.Source')]: data.source,
					[t('VehicleModel.DbcKey')]: data.dbcFileKey || "-",
					[t('VehicleModel.Vendor')]: data.deviceType || "-",
					[t('VehicleModel.Supported')]: data.isSupported ? t("VehicleModel.Yes") : (data.isSupported === undefined ? t("VehicleModel.Unknown") : t("VehicleModel.No")),
					[t('VehicleModel.Verified')]: data.verified ? t("VehicleModel.Yes") : t("VehicleModel.No")
				}

				if (can({ oneOfRole: [Role.ADMIN, Role.SITE_ADMIN] })) {
					parsedData = {
						[t('VehicleModel.Make')]: `"${data.make}"`,
						[t('VehicleModel.Model')]: `"${data.model}"`,
						[t('VehicleModel.Year')]: data.yearOfManufacture,
						[t('VehicleModel.Source')]: data.source,
						[t('VehicleModel.DbcKey')]: data.dbcFileKey || "-",
						[t('VehicleModel.Universal Kit #')]: (data[KitType.UNIVERSAL_KIT] || []).length ===0 ? "-" : '"'+Array.from(new Set((data[KitType.UNIVERSAL_KIT] || []).map(i=>i.sapCode))).join('\r\n')+'"',
						[t('VehicleModel.Kit #')]: (data[KitType.KIT] || []).length ===0 ? "-" : '"'+ Array.from(new Set((data[KitType.KIT] || []).map(i=>i.sapCode))).join('\r\n')+'"',
						[t('VehicleModel.Y Cable (Included with Kit)')]: (data[KitType.Y_CABLE_KIT] || []).length ===0 ? "-" : '"'+Array.from(new Set((data[KitType.Y_CABLE_KIT] || []).map(i=>i.sapCode))).join('\r\n')+'"',
						[t('VehicleModel.Additional Parts')]: (data[KitType.ADDITIONAL] || []).length ===0 ? "-" : '"'+Array.from(new Set((data[KitType.ADDITIONAL] || []).map(i=>i.sapCode))).join('\r\n')+'"',
						[t('VehicleModel.Vendor')]: data.deviceType || "-",
						[t('VehicleModel.Supported')]: data.isSupported ? t("VehicleModel.Yes") : (data.isSupported === undefined ? t("VehicleModel.Unknown") : t("VehicleModel.No")),
						[t('VehicleModel.Verified')]: data.verified ? t("VehicleModel.Yes") : t("VehicleModel.No")
					}
				}

				pidCategories.forEach(categoryName=>{
					const pids = (data.grouppedPid || {})[categoryName];
					if (pids && pids.length !== 0) {
						parsedData[categoryName] = '"'+Array.from(pids).join('\r\n')+'"';
					}
					else {
						parsedData[categoryName] = "-"
					}
				})

				return parsedData;
			})
		);
	}

	return (
		<div>
			<CsvLink data={csvData || []} ref={csvLinkRef} fileName={getExportFilename({
					entityName: t('VehicleModel.ExportFileName'),
					extension: ".csv"
				})} />
			<Row className='mb-2' style={{ marginTop: "10px", marginRight: "10px", textAlign: "right" }}>
				<Col xs={{ span: 24 }}>
					<Space>
						<ResponsiveButton
							onClick={() => {
								setNewVehicleModelModal(true);
							}}
							icon={<PlusCircleOutlined />}
							loading={isVehicleCompatibilityLoading}
							title={t("VehicleModel.AddVehicleModel")} />
						<Can requiredPermission={[Permission.EXPORT]}>
							<Buttons moreButtons={getExportOptionButtons({ handleExportExcel, handleExportCSV, isLoading: isVehicleCompatibilityLoading })}
								noMargin={true}>
								<ResponsiveButton loading={isVehicleCompatibilityLoading} disabled={filterVehicleCompatibility?.length === 0}
									title={t("Common.Export")}
									icon={<CloudDownloadOutlined />} />
							</Buttons>
						</Can>
					</Space>
				</Col>
			</Row>
			<Panel noPadding style={{ marginBottom: "15px" }}>
				<PanelBody>
					<Form form={filterForm} layout="inline" onFinish={onFinish} style={{ background: "#f2f2f2" }}>
						<Row className={`${style.fullWidthRow} ${style.searchPanelRow}`}>
							<Col xs={{ span: 16 }} xl={{ span: 16 }} md={{ span: 12 }} className={"mb-1"}>
								<Form.Item name="vinRegLic">
									<Select onChange={handleTagChange}
										maxTagCount="responsive"
										suffixIcon={<InfoOutlined onClick={() => { setInfoModalOpen(true) }} style={{
											backgroundColor: "#1677ff",
											display: "flex",
											fontSize: 10,
											borderRadius: 25,
											padding: 2,
											color: "#fff"
										}} />}
										defaultValue={searchObj?.any || []}
										tagRender={renderTag}
										option={(searchObj?.any || []).map(i=>{return{ value:i }})}
										placeholder={
											<SearchOutlined />
										}
										filterOption={(e, option) => option.disabled || option.value?.toString()?.toLowerCase().includes(e?.toString()?.toLowerCase())}
										mode="tags" style={{ width: '100%' }} />
								</Form.Item>
							</Col>
							<Col flex="auto" xs={{ span: 8 }} xl={{ span: 8 }} md={{ span: 12 }} className={"mb-1"}>
								<Space>
									<ResponsiveButton
										type="link" loading={isVehicleCompatibilityLoading}
										style={{ paddingLeft: "0px", paddingRight: "0px" }}
										onClick={() => { setShowAdvanceSearch(prev => !prev) }}
										title={t("Common.AdvanceSearch")}
										icon={showAdvanceSearch ? <UpOutlined /> : <DownOutlined />} />
									<ResponsiveButton
										loading={isVehicleCompatibilityLoading}
										onClick={() => { setShowCsvSearch(prev => !prev) }}
										title={t("VehicleModel.CsvSearch")}
										icon={<SearchOutlined />} />
								</Space>
							</Col>
						</Row>
						<Collapse ghost bordered={false} activeKey={showAdvanceSearch ? "advanceFilterPanel" : ""}
							style={{ background: "#f2f2f2", width: "100%" }} className='mb-2'>
							<CollapsePanel showArrow={false} key="advanceFilterPanel" className='invisible'>
								<FilterWrapper>
									<Form.Item name="make" style={{ margin: 0 }} initialValue={searchObj?.make || []}>
										<Multiselect
											options={fullValues.make}
											onFilterChange={onFilterChange}
											placeholder={t("VehicleModel.Make")}
											isLoading={isVehicleCompatibilityLoading} />
									</Form.Item>
									<Form.Item name="model" style={{ margin: 0 }} initialValue={searchObj?.model || []}>
										<Multiselect
											options={fullValues.model}
											onFilterChange={onFilterChange}
											placeholder={t("VehicleModel.Model")}
											isLoading={isVehicleCompatibilityLoading} />
									</Form.Item>
									<Form.Item name="year" style={{ margin: 0 }} initialValue={searchObj?.year || []}>
										<Multiselect
											options={fullValues.year}
											onFilterChange={onFilterChange}
											placeholder={t("VehicleModel.Year")}
											isLoading={isVehicleCompatibilityLoading} />
									</Form.Item>
									<Form.Item name="source" style={{ margin: 0 }} initialValue={searchObj?.source || []}>
										<Multiselect
											options={fullValues.source}
											onFilterChange={onFilterChange}
											placeholder={t("VehicleModel.Source")}
											isLoading={isVehicleCompatibilityLoading} />
									</Form.Item>
									<Form.Item name="deviceType" style={{ margin: 0 }} initialValue={searchObj?.deviceType || []}>
										<Multiselect
											options={fullValues.deviceType}
											onFilterChange={onFilterChange}
											placeholder={t("VehicleModel.Hardware")}
											isLoading={isVehicleCompatibilityLoading} />
									</Form.Item>
									<Form.Item name="company" style={{ margin: 0 }} initialValue={searchObj?.company || []}>
										<Multiselect
											options={fullValues.company}
											onFilterChange={onFilterChange}
											placeholder={t("VehicleModel.Company")}
											isLoading={isVehicleCompatibilityLoading} />
									</Form.Item>
									<Space>
										<Form.Item name="pid" style={{ margin: 0 }} initialValue={searchObj?.pid || []}>
											<ResponsiveFilter
												loading={isVehicleCompatibilityLoading}
												data={fullValues.pidList}
												onChange={(e) => {
													filterForm.submit()
												}} />
										</Form.Item>
										<ResponsiveButton
											onClick={() => { 
												filterForm.setFieldsValue({
													make: [], 
													pid: [], 
													model:[], 
													year: [], 
													source: [], 
													company: [], 
													deviceType: [], 
													busType: [], 
													any: null });
													
												filterForm.submit();
											}}
											title={t("Common.ClearFilter")}
											icon={<UndoOutlined />} />
									</Space>
								</FilterWrapper>
							</CollapsePanel>
						</Collapse>
					</Form>
					<Row className={style.fullWidthRow}>
						<Col xs={{ span: 24 }}>
							{isVehicleCompatibilityLoading || vehicleCompatibility === undefined ? <Skeleton active /> :
								<Table
									rowKey={(record) => record.rowId}
									columns={getVehicleModelDataColumns(onViewKit, can, companies)}
									dataSource={filterVehicleCompatibility}
									pagination={{
										current: currentPage,
										onChange: handlePageChange,
										showSizeChanger: true
									}}
									size="small"
									expandIcon={(props) => {
										if (props.expanded) {
											return <a style={{ color: 'black' }} onClick={e => {
												props.onExpand(props.record, e);
											}}><UpOutlined /></a>
										} else {
											return <a style={{ color: 'black' }} onClick={e => {
												props.onExpand(props.record, e);
											}}><DownOutlined /></a>
										}
									}}
									expandable={{
										onExpand:(expand, record)=>{
											if (expand && record.documentId) {
												dispatch(getVehicleModelDocumentById.initiate({ id: record.id }));
											}
										},
										expandedRowRender: (record) => {
											return (
												<Spin spinning={isVehicleCompatibilityLoading}>
													<Row>
														<Col xs={{ span: 24 }}>
															<Row>
																{InstallationSupportRender(record)}
																{compatibilityRender(record)}
															</Row>
														</Col>
													</Row>
													<Row>
														<Col xs={{ span: 24 }} className={style.showMorePanel}>
															<div>
																{scanRequestRender(record)}
																<Can requiredPermission={[Permission.AUDIT]}>
																	<ResponsiveButton type="link" onClick={() => {
																		history.push(RouteKey.AUDIT_VEHICLE_MODEL, { id: record.id, name: `${record.make} ${record.model} ${record.yearOfManufacture} (${record.source})`})
																	}}
																		title={t("Audits.Audits")}
																		icon={<AuditOutlined />} />
																</Can>
																<Can requiredPermission={[Permission.COMMON_EDIT]}>
																	<ResponsiveButton type="link" onClick={() => { history.push(RouteKey.VEHICLE_MODEL, { record: record }); }} title={t("Common.Edit")} icon={<EditOutlined />} />
																</Can>
																{(record.groupId === undefined || record.groupId === null) && !record.isDummyInternal &&
																	<Can requiredPermission={[Permission.MERGE_VEHICLE_MODEL]}>
																		<ResponsiveButton type="link" onClick={() => {
																			setSelectedVehicleCompatibility(record);
																			setVehicleMergeModal(true);
																		}} title={t("VehicleModel.Merge")} icon={<MergeCellsOutlined />} />
																	</Can>
																}
																{
																	((record.supportedKit || []).length !== 0 || (record.supportedPid || []).length !== 0 ||
																		(record.groupId !== undefined && vehicleCompatibility.filter(i=>i.groupId === record.groupId).length > 1)) &&
																	<Button type="link" className={style.showMoreLink} onClick={() => { 
																		setSelectedVehicleCompatibility(record);
																		setVehicleCompatibilityDetailModal(true);
																	 }}>
																		{t("Common.ShowMore")}
																		<RightOutlined />
																	</Button>
																}
															</div>
														</Col>
													</Row>
												</Spin>
											);
										},
										rowExpandable: (record) => true,
									}}/>
							}
						</Col>
					</Row>
				</PanelBody>
			</Panel>
			<VehicleCompatibilitySearchModal 
			  isModalOpen={showCsvSearch} 
			  vehicleCompatibilityList={vehicleCompatibility}
			  onComplete={(keywords, searchedData, totalRecord, matchedRecord)=>{
				filterForm.setFieldsValue({ ...filterForm.getFieldValue(), vinRegLic: keywords });
				setFilterVehicleCompatibility(searchedData);
				updateRowCount(searchedData.length);
				setShowCsvSearch(false);

				dispatch(
					addToast({
						title:i18n.t("VehicleModel.CsvSearchFinishTitle"),
						type:"success",
					    content: <Trans
							i18nKey={'VehicleModel.VehicleModelSearchResultSummary'}
							values={{ number: matchedRecord, total: totalRecord }}
							components={{ primary: <Text type="success" strong />, secondary: <Text strong /> }}
						/>,
					})
				  );
			  }}
			  onClose={()=>{setShowCsvSearch(false)}}/>
			<VehicleCompatibilityInfoModal 
			  isModalOpen={infoModelOpen} 
			  onClose={() => { setInfoModalOpen(false) }} />
			<VehicleCompatibilityResourceModal 
			  kitDetail={selectedKit} 
			  isModalOpen={selectedKit !== undefined} 
			  onClose={() => { setSelectedKit(undefined) }} />
			<VehicleCompatibilityDetailModal 
			  setSelectedKit={onViewKit} 
			  vehicleDetail={selectedVehicleCompatibility} 
			  isModalOpen={vehicleCompatibilityDetailModal} 
			  onClose={() => { 
				setSelectedVehicleCompatibility(undefined); 
				setVehicleCompatibilityDetailModal(false);
			  }} />
			<VehicleCompatibilityMergeModal 
			  isModalOpen={vehicleMergeModal}
			  vehicleDetail={selectedVehicleCompatibility} 
			  onClose={() => { 
				setSelectedVehicleCompatibility(undefined); 
				setVehicleMergeModal(false);
			  }}/>
			<VehicleMakeModelYearFormModal
			  isModalOpen={newVehicleModelModal}
			  onClose={() => {
				setNewVehicleModelModal(false);
			  }}/>
		</div>
	)
}

export default VehicleCompatibility;