import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Panel, PanelBody } from './../../components/panel/panel.js';
import { Table, Modal, Skeleton, Space, Form, Button, Row, Col, Select, Spin, Typography, Tag, notification } from 'antd';
import style from './VehicleCompatibility.module.scss';
import mergeStyle from './VehicleCompatibilityMergeModal.module.scss';
import VehicleCompatibilityMergeModal from "./VehicleCompatibilityMergeModal.js";
import VehicleModelRenameModal from "./VehicleModelRenameModal.js";
import FilterWrapper from "../../features/filterWrapper/FilterWrapper"

import {
	executeBulkUpdateVehicleModel,
	useBulkUpdateVehicleModelMutation,
	useUnverifiedVehicle
} from '../../features/vehicleModels/vehicleModelApi';
import { useCurrentCompany } from '../../features/company/companySlice.js';

import {
	UndoOutlined,
	InfoCircleFilled
} from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import { setBackButton, setPageTitle } from '../../features/header/headerSlice';
import { ResponsiveButton, ResponsiveFilter } from '../../features/responsive';
import Multiselect from '../../features/multiselect/Multiselect.js';
import { getVehicleModelCleanupDataColumns } from './VehicleModelHelper.js';
import { RouteKey } from '../../config/page-key.js';
import { useCan } from '../../features/user/Can.js';
import { Can } from "../../features/user/Can.js"
import { Permission } from '../../features/user/Permission.js';
import { confirmationModal } from '../../features/confirmationModal/confirmationModal.js';

const VehicleCleanup = () => {
	const { i18n, t } = useTranslation();
	const currentCompany = useCurrentCompany();
	const dispatch = useDispatch();
	const [filterForm] = Form.useForm();
	const [searchObj, setSearchObj] = useState({});
	const { vehicleModels, isFetching } = useUnverifiedVehicle(currentCompany?.id);
	const [filterVehicleModel, setFilterVehicleModel] = useState(vehicleModels);
	const [bulkUpdateVehicleModel, response] = useBulkUpdateVehicleModelMutation();
	const [vehicleMergeModal, setVehicleMergeModal] = useState(false);
	const [vehicleRenameModal, setVehicleRenameModal] = useState(false);
	const [selectedVehicleCompatibility, setSelectedVehicleCompatibility] = useState();
	const history = useHistory();
	const can = useCan();

	useEffect(() => {
		dispatch(setPageTitle(t("VehicleModel.VehicleModelCleanup")));

		return () => {
			dispatch(setPageTitle(""));
		}
	}, []);

	const fullValues = useMemo(() => {
		const yearValue = filterForm?.getFieldValue("year") || [];
		const makeValue = filterForm?.getFieldValue("make") || [];
		const modelValue = filterForm?.getFieldValue("model") || [];

		let makeList = [];
		let modelList = [];
		let yearList = [];

		(vehicleModels || []).map(makeModelYear => {
			const makeMatch = (makeValue?.length === 0 || makeValue?.includes(makeModelYear.make));
			const modelMatch = (modelValue?.length === 0 || modelValue?.includes(makeModelYear.model));
			const yearMatch = (yearValue?.length === 0 || yearValue?.includes(makeModelYear.year));

			if (makeMatch && yearMatch) {
				modelList.push(makeModelYear.model);
			}

			if (modelMatch && yearMatch) {
				makeList.push(makeModelYear.make);
			}

			if (modelMatch && makeMatch && makeModelYear.year) {
				yearList.push(makeModelYear.year);
			}
		});

		yearList = Array.from(new Set(yearList.map(i => i))).map(i => {
			return { label: i?.toString(), value: i }
		});

		modelList = Array.from(new Set(modelList.map(i => i?.trim()))).map(i => {
			return { label: i, value: i }
		})

		makeList = Array.from(new Set(makeList.map(i => i?.trim()))).map(i => {
			return { label: i, value: i }
		});

		return {
			make: makeList,
			model: modelList,
			year: yearList
		}
	}, [vehicleModels,
		filterForm?.getFieldValue("year"),
		filterForm?.getFieldValue("make"),
		filterForm?.getFieldValue("model")]);

	const onFinish = (e) => {
		setSearchObj({
			...searchObj,
			...{
				make: e.make,
				model: e.model,
				year: e.year,
			}
		});
	};

	const onFilterChange = value => {
		filterForm.submit();
	};

	useEffect(() => {
		let filteredData = vehicleModels || [];
		if (searchObj) {
			if (searchObj.make && searchObj.make.length !== 0) {
				filteredData = filteredData.filter(i => searchObj.make.includes(i.make.trim()));
			}

			if (searchObj.model && searchObj.model.length !== 0) {
				filteredData = filteredData.filter(i => searchObj.model.includes(i.model.trim()));
			}

			if (searchObj.year && searchObj.year.length !== 0) {
				filteredData = filteredData.filter(i => searchObj.year.includes(i.year));
			}
		}

		setFilterVehicleModel(filteredData);
	}, [searchObj, vehicleModels]);

	return (
		<div>
			<Panel noPadding style={{ marginBottom: "15px" }}>
				<PanelBody>
					<Form form={filterForm} layout="inline" onFinish={onFinish} style={{ background: "#f2f2f2" }}>
						<Row className={`${style.fullWidthRow} ${style.searchPanelRow}`}>
							<Col xs={{ span: 24 }}>
								<FilterWrapper size="medium">
									<Form.Item name="make" style={{ margin: 0 }} initialValue={searchObj?.make || []}>
										<Multiselect
											options={fullValues.make}
											onFilterChange={onFilterChange}
											placeholder={t("VehicleModel.Make")}
											isLoading={isFetching} />
									</Form.Item>
									<Form.Item name="model" style={{ margin: 0 }} initialValue={searchObj?.model || []}>
										<Multiselect
											options={fullValues.model}
											onFilterChange={onFilterChange}
											placeholder={t("VehicleModel.Model")}
											isLoading={isFetching} />
									</Form.Item>
									<Form.Item name="year" style={{ margin: 0 }} initialValue={searchObj?.year || []}>
										<Multiselect
											options={fullValues.year}
											onFilterChange={onFilterChange}
											placeholder={t("VehicleModel.Year")}
											isLoading={isFetching} />
									</Form.Item>
									<ResponsiveButton
										onClick={() => {
											filterForm.setFieldsValue({
												make: [],
												model: [],
												year: [],
											});

											filterForm.submit();
										}}
										title={t("Common.ClearFilter")}
										icon={<UndoOutlined />} />
								</FilterWrapper>
							</Col>
						</Row>
					</Form>
					<Row className={style.fullWidthRow}>
						<Col xs={{ span: 24 }}>
							{isFetching || vehicleModels === undefined ? <Skeleton active /> : <Table
								size="small" columns={getVehicleModelCleanupDataColumns({
									renameVehicleModel: (e) => {
										setSelectedVehicleCompatibility(e)
										setVehicleRenameModal(true);
									},
									markVerified: (e) => {
										confirmationModal(
											t('VehicleModel.VerifyVehicleModelModal.Title'),
											t('VehicleModel.VerifyVehicleModelModal.Description', {source:e?.make, target:e?.expectedMake}),
											t('Common.ConfirmButton'),
											t('Common.CancelButton'),
											() => {
												executeBulkUpdateVehicleModel({ id: e.id, action: "verify" }, bulkUpdateVehicleModel, dispatch);
											}
										);
									},
									audit : (e) =>{
										history.push(RouteKey.AUDIT_VEHICLE_MODEL, { id: e.id, name: `${e.make} ${e.model} ${e.yearOfManufacture} (${e.source})`})
									},
									mergeVehicleModel: (e) => {
										setSelectedVehicleCompatibility(e)
										setVehicleMergeModal(true);
									},
								})}
								dataSource={filterVehicleModel} />
							}
						</Col>
					</Row>
				</PanelBody>
			</Panel>
			<VehicleCompatibilityMergeModal
				isModalOpen={vehicleMergeModal}
				vehicleDetail={selectedVehicleCompatibility}
				onClose={() => {
					setSelectedVehicleCompatibility(undefined);
					setVehicleMergeModal(false);
				}} />
			<VehicleModelRenameModal
				isModalOpen={vehicleRenameModal}
				vehicleDetail={selectedVehicleCompatibility}
				onClose={() => {
					setSelectedVehicleCompatibility(undefined);
					setVehicleRenameModal(false);
				}} />
		</div>
	)
}

export default VehicleCleanup;