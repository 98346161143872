import React, { useState, useMemo, useRef, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { Panel, PanelBody } from './../../components/panel/panel.js';
import { Table, InputNumber, Space, Spin, Form, Button, Row, Col, Input, Select, AutoComplete } from 'antd';
import style from './VehicleCompatibility.module.scss';
import { useDispatch } from 'react-redux';
import { setBackButton, setPageTitle } from '../../features/header/headerSlice';
import { useSaveVehicleModelMutation, useGetVehicleModelByIdQuery, executeSaveVehicleModel, useDeleteVehicleModelMutation, executeDeleteVehicleModel, useVehicleModelWithSource } from '../../features/vehicleModels/vehicleModelApi.js';
import { RouteKey } from '../../config/page-key.js';
import { Permission } from '../../features/user/Permission.js';
import { DeleteOutlined } from '@ant-design/icons';
import { Can, useCan } from '../../features/user/Can.js';
import { ResponsiveButton } from '../../features/responsive';
import { confirmationModal } from '../../features/confirmationModal/confirmationModal.js';
import formStyles from './VehicleMakeModelYearFormModal.module.scss';
import { useCurrentCompany } from '../../features/company/companySlice.js';

import { useFuzzySearch } from '../../utils/fuzzySearch.js';
import { SupportedFlag, VehicleModelFormItem } from './constant.js';
const { Option } = Select;

const VehicleModelForm = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const { i18n, t } = useTranslation();
	const { data, isFetching } = useGetVehicleModelByIdQuery({ id: history.location.state.record.id }, { skip: history.location?.state?.record?.id === undefined });
	const [isDirty, setIsDirty] = useState(false);
	const [hasDbcOption, setHasDbcOption] = useState(false);
	const [dbcOption, setDbcOption] = useState([]);
	const [saveVehicleModel] = useSaveVehicleModelMutation();
	const [deleteVehicleModel] = useDeleteVehicleModelMutation();
	const [form] = Form.useForm();
	const currentCompany = useCurrentCompany();
	const { vehicleModels: makeModelYearList } = useVehicleModelWithSource(currentCompany?.id)
	const { performIndividualKeySearch } = useFuzzySearch(makeModelYearList || [], ['make', 'model', 'yearOfManufacture']);
	const can = useCan();
	const canDelete = can({ everyPermission: Permission.COMMON_EDIT }) && 
	  (history.location?.state?.record?.supportedPid || []).length == 0 && 
	  (history.location?.state?.record?.supportedKit || []).length == 0 && 
	  data?.company?.id !== undefined &&
	  data?.groupId === undefined;
	
	const [localTempSearchedValue, setLocalTempSearchedValue] = useState({
		make: "",
		model: "",
		yearOfManufacture: ""
	});

	const formItems = VehicleModelFormItem(t);

	useEffect(() => {
		if (data) {
			dispatch(setPageTitle(data.make + " " + data.model + " " + data.yearOfManufacture));
		}
		else {
			dispatch(setPageTitle(t("VehicleModel.NewVehicleModel")));
		}

		dispatch(setBackButton(true));

		return () => {
			dispatch(setPageTitle(""));
			dispatch(setBackButton(false));
		}
	}, [data, t]);

	const onFinish = (e) => {
		let payload = { ...data, ...e };

		if (e.dbc) {
			payload.dbcFileKey = e.dbc;
		}

		if (payload.extras) {
			const oldExtrasObj = JSON.parse(payload.extras);

			if (oldExtrasObj.dbcAvailable) {
				const newExtrasObj = JSON.parse(payload.extras);
				const selectedDbc = oldExtrasObj.dbcAvailable.find(i => i.dbc_file_key === payload.dbcFileKey);

				newExtrasObj.baudrate = selectedDbc.baudrate;

				newExtrasObj.dbcAvailable.forEach(i => {
					delete i["is_default"];
				})
				payload.extras = JSON.stringify(newExtrasObj);
			}
		}

		if (payload.isSupported === SupportedFlag.NOT_SUPPORTED) {
			payload.isSupported = false;
		}
		else if(payload.isSupported === SupportedFlag.SUPPORTED) {
			payload.isSupported = true;
		}
		else{
			delete payload["isSupported"];
		}

		delete payload["dbc"];
		executeSaveVehicleModel(payload, saveVehicleModel, dispatch).then(() => {
			if (history.length > 2) {
				history.goBack();
			}
			else {
				history.push(RouteKey.VEHICLE_COMPATIBILITY);
			}
		})
	};

	useEffect(() => {
		let formData = {
			make: data?.make,
			mode: data?.model,
			yearOfManufacture: data?.yearOfManufacture,
			priority: data?.priority,
			isSupported: data?.isSupported !== undefined ? (data.isSupported ? SupportedFlag.SUPPORTED :SupportedFlag.NOT_SUPPORTED) : SupportedFlag.UNKNOWN,
			source: data?.displaySource !== undefined ? data?.displaySource : data?.source
		};

		if (data?.dbcFileKey) {
			let dbcOptionAvailable = [];
			dbcOptionAvailable.push({ label: data?.dbcFileKey, value: data?.dbcFileKey });
			if (data?.extras) {
				const vmExtras = JSON.parse(data.extras);
				if (vmExtras.dbcAvailable) {
					dbcOptionAvailable = [];
					vmExtras.dbcAvailable.forEach(dbcOpt => {
						dbcOptionAvailable.push({ label: dbcOpt?.dbc_file_key, value: dbcOpt?.dbc_file_key });
					})
					setHasDbcOption(true);
				}
			}
			setDbcOption(dbcOptionAvailable)
			formData["dbc"] = data?.dbcFileKey;
		}

		form.setFieldsValue({ ...data, ...formData });
	}, [form, data]);

	const renderFormItems = formItems => {
		return formItems.map((formItem, formItemIndex) => {
			const { name, label, rules } = formItem;
			return (
				<Col xs={{ span: 24 }} sm={{ span: 12 }}>
					<Form.Item
						key={`${formItemIndex}-${name}`}
						name={name}
						labelAlign="left"
						className={style.inputLeft}
						label={label}
						colon={false}
						rules={rules}
					>
						{renderFormItemChild(name)}
					</Form.Item>
				</Col>
			);
		});
	};

	const renderNotFoundPanel = type => {
		let possibleMatch = [];
		if (localTempSearchedValue.value) {
			const possibleMatchModel = performIndividualKeySearch(localTempSearchedValue.value || '', type);
			possibleMatch = Array.from(new Set(possibleMatchModel.map(i => i[type]))).slice(0, 5);
		}

		if ((possibleMatch || []).length !== 0) {
			return (
				<div style={{ padding: "0px 10px" }}>
					<div style={{ display: 'flex', flexDirection: 'column' }}>
						{(possibleMatch || []).map((data, index) => (
							<a
								key={index}
								className={formStyles.makeModelYearSuggestionValue}
								onClick={() => {
									form.setFieldValue(type, data);
									document.activeElement.blur();
								}}
							>
								{data}
							</a>
						))}
					</div>
				</div>
			);
		}
	};

	const handleValueSearch = (value, type) => {
		value && setLocalTempSearchedValue({ value, type });
	};

	const renderFormItemChild = (name) => {
		const options = Array.from(new Set((makeModelYearList || []).map(i => i[name])))
			.filter(i => i?.toString() !== undefined && i?.toString()?.length !== 0)
			.map(data => {
				return { label: data?.toString(), value: data?.toString() };
			});

		return (
			<AutoComplete
				onSelect={e => {
					form.setFieldValue(name, e);
				}}
				options={options}
				filterOption={(inputValue, option) =>
					option.value
						?.toString()
						.toUpperCase()
						.indexOf(inputValue?.toString().toUpperCase()) !== -1
				}
				disabled={data?.groupId !== undefined}
				onFocus={() => {
					handleValueSearch(form.getFieldValue(name), name);
				}}
				onSearch={e => {
					handleValueSearch(e, name);
				}}
				notFoundContent={renderNotFoundPanel(name)}
			/>
		);
	};

	return (
		<Spin spinning={isFetching}>
			<Panel style={{ marginBottom: "15px" }} newButtonAction={canDelete ? () => {
				return (
					<Space>
						<Can requiredPermission={[Permission.COMMON_EDIT]}>
							<ResponsiveButton danger disabled={!canDelete} onClick={() => {
								confirmationModal(
									t('VehicleModel.DeleteVehicleModelTitle', { vehicleModel: data?.make + " " + data?.model + " " + data?.yearOfManufacture }),
									t('VehicleModel.DeleteVehicleModelDescription', { vehicleModel: data?.make + " " + data?.model + " " + data?.yearOfManufacture }),
									t('Common.Delete'),
									t('Common.CancelButton'),
									() => {
										executeDeleteVehicleModel({ id: data.id }, deleteVehicleModel, dispatch).then(() => {
											if (history.length > 2) {
												history.goBack();
											}
											else {
												history.push(RouteKey.VEHICLE_COMPATIBILITY);
											}
										})
									},
									'delete'
								);
							}}
								title={t("Common.Delete")}
								icon={<DeleteOutlined />} />
						</Can>
					</Space>
				)
			} : null}>
				<PanelBody>
					<Form form={form} layout="vertical" onFinish={onFinish} onValuesChange={() => { setIsDirty(true) }}>
						<Row className={style.fullWidthRow}>
							{renderFormItems(formItems)}
							<Col xs={{ span: 24 }} sm={{ span: 12 }}>
								<Form.Item
									label={t("VehicleModel.Priority")}
									name="priority"
									className={style.inputLeft}
									rules={[{ required: true, message: t("ValidationErrors.PriorityRequired") }]}
								>
									<InputNumber style={{ width: "100%" }} />
								</Form.Item>
							</Col>
							<Col xs={{ span: 24 }} sm={{ span: 12 }}>
								<Form.Item
									name="isSupported"
									label={t("VehicleModel.Supported")}
									className={style.inputLeft}
								>
									<Select>
										<Option value={SupportedFlag.UNKNOWN}>{t("VehicleModel.Unknown")}</Option>
										<Option value={SupportedFlag.SUPPORTED}>{t("VehicleModel.Yes")}</Option>
										<Option value={SupportedFlag.NOT_SUPPORTED}>{t("VehicleModel.No")}</Option>
									</Select>
								</Form.Item>
							</Col>
							<Col xs={{ span: 24 }} sm={{ span: 12 }}>
								<Form.Item
									label={t("VehicleModel.Source")}
									name="source"
									className={style.inputLeft}
								>
									<Input disabled={true} />
								</Form.Item>
							</Col>
							<Col xs={{ span: 24 }} sm={{ span: 12 }}>
								<Form.Item
									name="dbc"
									label={t("VehicleModel.DbcKey")}
									className={style.inputLeft}>
									{
										dbcOption.length !== 0 && hasDbcOption ?
											<Select options={dbcOption} disabled={dbcOption.length <= 1} /> :
											<Input />
									}
								</Form.Item>
							</Col>
						</Row>
						<div className={"formFooter"}>
							<Space>
								<Button
									type="primary"
									size="large"
									htmlType="submit"
									disabled={!isDirty}
								>
									{t('Common.SaveButton')}
								</Button>
								<Button size="large" onClick={history.goBack}>
									{t('Common.CancelButton')}
								</Button>
							</Space>
						</div>
					</Form>
				</PanelBody>
			</Panel>
		</Spin>
	)
}

export default VehicleModelForm;