import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Space, Form, Button, Modal, Col, Row, Select, Typography, Spin, Input } from 'antd';
import { ResponsiveModal } from '../../features/responsive';
import { executeBulkUpdateVehicleModel, useBulkUpdateVehicleModelMutation } from '../../features/vehicleModels/vehicleModelApi.js';
import { useDispatch } from 'react-redux';

const VehicleModelRenameModal = ({ isModalOpen, onClose, vehicleDetail }) => {
	const { i18n, t } = useTranslation();
	const [form] = Form.useForm();
	const dispatch = useDispatch();
	const [bulkUpdateVehicleModel, response] = useBulkUpdateVehicleModelMutation();

	useEffect(()=>{
		if (vehicleDetail && isModalOpen) {
			form.setFieldValue("originalMake", vehicleDetail.make);
			form.setFieldValue("make", vehicleDetail.expectedMake);
		}
	}, [vehicleDetail, isModalOpen])

	const onFinish = (e) =>{
		const payload ={
			id : vehicleDetail.id,
			action: "rename",
			to: e.make
		}

		executeBulkUpdateVehicleModel(payload, bulkUpdateVehicleModel, dispatch).then(() => {
			if (onClose) {
				onClose();
			}
		})
	}

	return (
		<>
			<ResponsiveModal
				onClose={onClose}
				isModalOpen={isModalOpen}
				width={"35%"}
				title={t("VehicleModel.RenameVehicleModelModal.Title")}
				style={{ minWidth: 350 }}
				footer={null}
			>
				<Form form={form} layout="vertical" autoComplete="off" onFinish={onFinish}>
					<Row style={{ marginBottom: "30px" }}>
						<Col xs={{ span: 24 }} className={"mb-2"}>
							<Form.Item name="originalMake" style={{ margin: 0 }}
								initialValue={vehicleDetail?.make}
								label={t("VehicleModel.RenameVehicleModelModal.OriginalMake")}>
								<Input disabled={true} />
							</Form.Item>
						</Col>
						<Col xs={{ span: 24 }} className={"mb-2"}>
							<Form.Item name="make" style={{ margin: 0 }}
								rules={[{ required: true, message: t("ValidationErrors.NewMakeIsRequired") }]}
								label={t("VehicleModel.RenameVehicleModelModal.NewMake")}>
								<Input />
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col xs={{ span: 24 }}>
							<Space>
								<Button 
								    htmlType="submit"
									type="primary">
									{t("VehicleModel.Rename")}
								</Button>
								<Button onClick={onClose}>
									{t("Common.CancelButton")}
								</Button>
							</Space>
						</Col>
					</Row>
				</Form>
			</ResponsiveModal>
		</>
	)
}

export default VehicleModelRenameModal;