export const RouteKey = {
  DEFAULT: '/',
  AUDIT_VEHICLE_MODEL: '/audit_vehicle_model',
  AUDIT_VEHICLE_MODEL_POLL: '/audit_vehicle_model_poll',
  AUDIT_ATTACHMENT: '/audit_attachment',
  AUDIT_RESOURCE: '/audit_resource',
  SSO: '/sso',
  LOGIN: '/login',
  VEHICLE_COMPATIBILITY: '/vehicleCompatibility',
  UNVERIFIED_VEHICLE: '/unverifedModel',
  SCANNING_REQUEST: '/scanningRequest',
  LOGOUT: '/logout',
  SAP: '/sap',
  DUMMY: '/dummy',
  DOCUMENT: '/document',
  VEHICLE_MODEL: '/vehicleModel',
  SAP_FORM: '/sapForm'
};