import React, {useState, useEffect} from 'react';
import { RouteKey } from '../../config/page-key';
import {
	SearchOutlined, FileTextOutlined, ToolOutlined, QuestionCircleOutlined, ScanOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import { useCan } from '../../features/user/Can';
import { useUserInfo } from '../../features/user/userApi';
import { FeatureFlag } from '../../features/user/Permission';

const SidebarNav = (props) =>{
	const { i18n, t } = useTranslation();
	const history = useHistory();
	const location = useLocation();
	const userInfo = useUserInfo();
	const can = useCan();
	
	const menu = [
		{
			key: RouteKey.VEHICLE_COMPATIBILITY, icon: <SearchOutlined />, label: t('Nav.Search')
		},
		{
			key: RouteKey.SAP, icon: <ToolOutlined />, label: t('Nav.Resource'), otherConditions: [() => userInfo.isAdminOrSiteAdmin]
		},
		{
			key: RouteKey.DOCUMENT, icon: <FileTextOutlined />, label: t('Nav.SupportDoc'), otherConditions: [() => userInfo.isAdminOrSiteAdmin]
		},
		{
			key: RouteKey.UNVERIFIED_VEHICLE, icon: <QuestionCircleOutlined />, label: t('Nav.UnverifedModel'), otherConditions: [() => userInfo.isAdminOrSiteAdmin]
		},
		{
			key: RouteKey.SCANNING_REQUEST, icon: <ScanOutlined />, label: t('Nav.ScanningRequests')
		}
	]

	const onMenuClick = (e)=>{
		history.push(e.key)
	}

	return (
		<Menu theme="dark" defaultSelectedKeys={[location.pathname]} onClick={onMenuClick} mode="inline" items={menu.filter(menu => can({ ...menu }))} />
	);
}

export default SidebarNav;