import React, { useState, useMemo, useRef, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { getExportFilename, CsvSearchTemplateHeader } from './VehicleModelHelper.js'
import { Space, Form, Button, Upload, Col, Row, Alert, Typography, Spin } from 'antd';
import style from './VehicleCompatibilitySearchModal.module.scss';
import { ResponsiveModal } from '../../features/responsive';
import { useFuzzySearch } from '../../utils/fuzzySearch.js';
import { getFileSize } from '../../utils/files.js';

import {
	UploadOutlined,
	CloseOutlined,
	LoadingOutlined,
	CheckCircleOutlined,
	FileTextOutlined
} from '@ant-design/icons';

import { CSVLink } from 'react-csv';
const { Text, Title, Link } = Typography;

const VehicleCompatibilitySearchModal = ({ isModalOpen, onClose, onComplete, vehicleCompatibilityList }) => {
	const { Dragger } = Upload;
	const { i18n, t } = useTranslation();
	const csvLinkRef = useRef(null);
	const [selectedFile, setSelectedFile] = useState(null);
	const [rowUploaded, setRowUploaded] = useState(0);
	const [isReadingFile, setIsReadingFile] = useState(false);
	const [searchedData, setSearchedData] = useState();
	const [matchedRowCount, setMatchRowCount] = useState(0);
	const [keywords, setKeywords] = useState();
	const keys = ['make', 'model', 'yearOfManufacture', 'source', 'fullText'];;
	const { performSearch } = useFuzzySearch(vehicleCompatibilityList, keys);
	const isFileInvalid = selectedFile && !selectedFile?.name.endsWith(".csv");

	useEffect(() => {
		if (isModalOpen) {
			setSelectedFile(null);
			setSearchedData(undefined);
			setKeywords(undefined);
			setMatchRowCount(0);
		}
	}, [isModalOpen])

	const readFile = (file) => {
		const reader = new FileReader();

		reader.onload = (e) => {
			setTimeout(() => {
				const content = e.target.result;
				let rows = content.split('\n');

				rows = rows.slice(1).map(i => i.replace('\r', '')).filter(i=>i && i?.trim()?.length!== 0);
				setRowUploaded(rows.length);
				
				rows = rows.map(data => {
					let targetVehicleModel = data.split(",");

					if (targetVehicleModel[0] && targetVehicleModel[1] && targetVehicleModel[2]){
						return `or${targetVehicleModel[0] ? " make:=\""+targetVehicleModel[0]+"\"" : ""}${targetVehicleModel[1] ? " model:=\""+targetVehicleModel[1]+"\"" : ""}${targetVehicleModel[2] ? " year:="+targetVehicleModel[2] : ""}`.replace(/\\/g, '');
					}
				}).filter(i=> i !== undefined);

				const targetKeywords = Array.from(new Set(rows));
				const filteredData = performSearch(targetKeywords, keys);
				const matchUniqueRowCount = (Array.from(new Set(filteredData.map(i=>{
					return JSON.stringify({
						make : i.make,
						model : i.model,
						yearOfManufacture : i.yearOfManufacture,
					})
				}))).map(item => JSON.parse(item)));
				
				setKeywords(targetKeywords)
				setSearchedData(filteredData);
				setMatchRowCount((matchUniqueRowCount || []).length);
				setIsReadingFile(false);
			}, 500);
		};

		setIsReadingFile(true);
		reader.readAsText(file);
	};
	
	return (
		<ResponsiveModal
			onClose={onClose}
			isModalOpen={isModalOpen}
			width={"35%"}
			title={t("VehicleModel.CsvSearch")}
			style={{ minWidth: 350 }}
			footer={null}
		>
			<CSVLink
				enclosingCharacter={``}
				data={CsvSearchTemplateHeader}
				style={{display: "none"}}
				filename={getExportFilename({
					entityName : t("VehicleModel.VehicleModelTemplate"),
					extension: ".csv"
				})}
				ref={csvLinkRef}
			></CSVLink>
			<Row>
				<Col xs={{ span: 24 }} className={"mb-2"} style={{ padding: "15px 0px" }}>
					<Space direction={"vertical"} className={style.fullWidthRow}>
						<Form.Item style={{ margin: 0 }}>
							<Alert
								description={
									<Trans
										i18nKey={'VehicleModel.CsvSearchHint'}
										components={{
											1: (
												<Link
													onClick={() => {
														csvLinkRef?.current?.link?.click()
													}}
												/>
											)
										}}
									/>
								}
								type="info"
							/>
						</Form.Item>
						<Form.Item
							name="fileName"
							style={{ margin: 0 }}
							rules={[{ required: true, message: t("ValidationErrors.DocumentRequired") }, {pattern:/\.csv$/, message: t("ValidationErrors.InvalidDocument")}]}>
							<Dragger
								showRemoveIcon={true}
								accept={".csv"}
								beforeUpload={file => {
									setSelectedFile(file);
									if (file.type === "text/csv") {
										readFile(file)
										return false;
									}
								}}
								height={'fit-content'}
								showUploadList={false}
							>
								{selectedFile && !isFileInvalid ? (
									<div className={style.centerPanel}>
										<Space style={{ flex: "1", justifyContent: "space-between", padding: "5px 10px", alignItems: "center" }}>
											<div className={style.centerPanel} style={{ flex: "0.8" }}>
												<FileTextOutlined style={{ color: "#389E0D", marginRight: 15, fontSize: 30 }} />
												<span className={style.centerPanel} style={{ flexDirection: "column" }}>
													<Text type="secondary" className={style.fullWidthRow}>{selectedFile.name}</Text>
													<Text type="secondary" className={style.fullWidthRow} style={{ textAlign: "left" }}>{getFileSize(selectedFile)}</Text>
												</span>
											</div>
											{!isReadingFile ? <div className={style.centerPanel} style={{ flex: "0.2" }}>
												<CheckCircleOutlined style={{ color: "#86B341", fontSize: 30 }} />
											</div>
												: <div style={{ flex: "0.2" }}>
													<Spin indicator={<LoadingOutlined style={{ fontSize: 30 }} />} />
												</div>}
										</Space>
									</div>
								) : (
									<div className={style.centerPanel}>
										<div style={{ flex: "1" }}>
											{t('VehicleModel.UploadCsvSearch')}&nbsp;
											<UploadOutlined />
										</div>
									</div>
								)}
							</Dragger>
							{isFileInvalid && <Text type="danger">{t("ValidationErrors.InvalidDocument")}</Text>}
						</Form.Item>
						{!isFileInvalid && searchedData &&
							<Form.Item style={{ textAlign: "center" }}>
								<Trans
									i18nKey={'VehicleModel.VehicleModelSearchResultSummary'}
									values={{ number: matchedRowCount, total: rowUploaded }}
									components={{ primary: <Text type="success" strong />, secondary: <Text strong /> }}
								/>
							</Form.Item>
						}
					</Space>
				</Col>
			</Row>
			<Row>
				<Col xs={{ span: 24 }}>
					<Space>
						<Button disabled={selectedFile === null || isReadingFile} onClick={() => {
							onComplete(keywords, searchedData, rowUploaded, matchedRowCount)
						}} type="primary">
							{t("Common.ContinueButton")}
						</Button>
						{(!selectedFile || isFileInvalid) &&
							<Button onClick={onClose}>
								{t("Common.CancelButton")}
							</Button>
						}
					</Space>
				</Col>
			</Row>
		</ResponsiveModal>
	)
}

export default VehicleCompatibilitySearchModal;