import React from 'react';
import { Row, Col } from 'antd';
import styles from './FilterWrapper.module.scss';
import { useTranslation } from 'react-i18next';

const FilterWrapper = ({ children, recordCount, size="small" }) => {
	const { i18n, t } = useTranslation();
  const sizing = {
    "small":{
      xs:12,
      lg:3,
      xl:2
    },
    "medium" :{
      xs:24,
      lg:4,
      xl:3
    },
    "large" :{
      xs:24,
      lg:10,
      xl:6
    }
  }

  return (
    <div className={styles.container}>
      <Row style={{display:"flex", justifyContent:"space-around", width:"100%"}}>
        <Col xs={{ span: recordCount ? 20 : 24 }}>
          <Row className={styles.row}>
            {React.Children.map(children, (child, index) => {
              if (child && child !== null) {
                return (
                  <Col  className={"mb-1"} xs={{ span: sizing[size].xs }} lg={{ span: sizing[size].lg }} xl={{ span: sizing[size].xl }} style={{ paddingRight: "4px" }}>
                    {child}
                  </Col>
                );
              }
            })}
          </Row>
        </Col>
        {recordCount &&
          <Col xs={{ span: 4 }} style={{ display: "flex" }}>
            <label
              style={{
                display: 'flex',
                width: '100%',
                marginBottom: 0,
                paddingRight: '20px',
                alignItems: 'center',
                justifyContent: 'flex-end'
              }}
            >
              {recordCount}{' '}{t("Common.RecordFound")}
            </label>
          </Col>
        }
      </Row>
    </div>
  );
};

export default FilterWrapper;
