export const KitType = {
	UNIVERSAL_KIT: "U",
	KIT: "K",
	Y_CABLE_KIT: "Y",
	ADDITIONAL: "A"
}

export const SupportedFlag = {
	UNKNOWN: 1,
	SUPPORTED: 2,
	NOT_SUPPORTED: 3
}

export const VehicleModelFormItem = (t) => [
	{
		name: 'make',
		label: t('VehicleModel.Make'),
		rules: [
			{
				required: true,
				message: t('ValidationErrors.MakeRequired')
			},
			{
				pattern: new RegExp(/^[^!@#$%^&*()_+=\-[\]{}|\\:;"'<>,.?/~` ]/),
				message: t('ValidationErrors.InvalidMakeFormat')
			}
		]
	},
	{
		name: 'model',
		label: t('VehicleModel.Model'),
		rules: [
			{
				required: true,
				message: t('ValidationErrors.ModelRequired')
			},
			{
				pattern: new RegExp(/^[^!@#$%^&*()_+=\-[\]{}|\\:;"'<>,.?/~` ]/),
				message: t('ValidationErrors.InvalidModelFormat')
			}
		]
	},
	{
		name: 'yearOfManufacture',
		label: t('VehicleModel.Year'),
		rules: [
			{
				required: true,
				message: t('ValidationErrors.YearRequired')
			},
			{
				pattern: new RegExp(/^[1-9]\d*$/),
				message: t('ValidationErrors.InvalidManufactureYearFormat')
			}
		]
	}
];