import React, {useEffect, useState} from 'react';
import { Popover, Drawer, Badge } from 'antd';
import { useMediaQuery } from 'react-responsive'
import { ResponsiveButton } from './ResponsiveButton.js'
import VerticalFilter from '../verticalFilter/VerticalFilter.js'
import { useTranslation } from 'react-i18next';
import { 
	FilterOutlined
} from '@ant-design/icons';

export const ResponsiveFilter = ({ data, value, onChange, ...props }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 })
	const { i18n, t } = useTranslation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [filterCount, setFilterCount] = useState(0);

    useEffect(()=>{
        setFilterCount((value ||[]).length)
    },[value])

    const onFilterChange = (value) =>{
        setFilterCount((value ||[]).length)
        if (onChange){
            onChange(value)
        }
    }

    if (isMobile) {
        return (
            <Badge count={filterCount}>
                <ResponsiveButton {...props}
                    title={t("Common.Filter")}
                    onClick={() => { setDrawerOpen(true) }}
                    icon={<FilterOutlined />} />
                <Drawer
                    placement={"bottom"}
                    onClose={() => setDrawerOpen(false)}
                    height={"auto"}
                    bodyStyle={{ padding: 0 }}
                    open={drawerOpen}>
                    <VerticalFilter data={data} value={value} onChange={onFilterChange} />
                </Drawer>
            </Badge>
        )
    }
    else{
        return (
            <Popover {...props} overlayClassName={"slimPopover"} placement="bottom" content={
                <VerticalFilter data={data} value={value} onChange={onFilterChange} />
            } trigger="click">
                <Badge count={filterCount}>
                    <ResponsiveButton {...props}
                        title={t("Common.Filter")}
                        icon={<FilterOutlined />} />
                </Badge>
            </Popover>
        );
    }
}