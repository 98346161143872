import React from 'react';
import DropdownLanguage from './dropdown/language.js';
import DropdownProfile from './dropdown/profile.js';
import { Breadcrumb, Button, Layout, Space } from 'antd';
import HelpCenter from './dropdown/helpCenter.js'
import style from './header.module.scss'
import { LeftOutlined } from '@ant-design/icons';
import { useBackButton, usePageTitle } from '../../features/header/headerSlice';
import { useHistory } from 'react-router-dom';
import headerStyles from './header.module.scss';
import { Typography } from 'antd';
const { Header : AntHeader, Content, Footer } = Layout;
const { Title } = Typography;

const Header = () => {
	const showBackButton = useBackButton();
	const pageTitle = usePageTitle();
	const history = useHistory();

	function itemRender(route) {
		return <Title level={5} style={{marginBottom:0, marginTop:0}}>{route.title}</Title>;
	}

	const leftNav = () => {
		return (
			<div style={{ display: "flex" }}>
				<Button type="link"
					style={{
						height: "100%",
						paddingLeft: showBackButton ? "5px" : "0px",
						paddingRight: showBackButton ? "10px" : "0px",
						visibility: showBackButton ? "visible" : "hidden"
					}}
					onClick={() => { history.goBack() }}
					className={headerStyles.breadCrumb}>
					<LeftOutlined />
				</Button>
				{pageTitle && <Breadcrumb className={style.headerBreadCrumb} items={[
					{
						breadcrumbName: pageTitle,
					}
				]} itemRender={itemRender} />}
			</div>
		)
	}

	return (
		<AntHeader style={{background:"#fff", paddingInline:10, paddingBottom:"10px", display:"flex", justifyContent:"center"}}>
			<div style={{ display: "flex", justifyContent: "space-between", width:"100%" }}>
				{leftNav()}
				<Space className={style.header} size={16} >
					<DropdownLanguage />
					{/*<DropdownCompany />*/}
					{<HelpCenter />}
					<DropdownProfile />
				</Space>
			</div>
		</AntHeader>
	)
}

export default Header;
