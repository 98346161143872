import i18n from 'i18next';
import moment from 'moment';
import { Table, Col, Row, Button, Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Permission, Role } from '../../features/user/Permission';
import { KitType } from './constant';
import { Buttons } from '../../components/control/Buttons';
import { Can } from '../../features/user/Can';

const statusColors = {
	IN_REVIEW: 'blue',
	APPROVED: 'green',
	DECLINED: 'red'
  };

export const getExportOptionButtons = ({
	handleExportExcel,
	handleExportCSV,
	isLoading = false
}) => {
	return [
		{
			name: i18n.t('Common.ExportToCSV'),
			onClick: handleExportCSV,
			disabled:isLoading,
			value: 'btn_exportCsv'
		},
		{
			name: i18n.t('Common.ExportToExcel'),
			onClick: handleExportExcel,
			disabled:isLoading,
			value: 'btn_exportExcel'
		}
	];
};

const renderKit = (kitDetail, onViewKit, vehicleModel, viewable = true) =>{
	return (
		<Row className="mb-1">
		{
			kitDetail.map(data => {
				return (
					<Col xs={{ span: 24 }}>
						{viewable ? <Button onClick={() => onViewKit(data, vehicleModel)} type="link">{data?.sapCode || "-"}</Button> : <div>{data?.sapCode || "-"}</div>}
					</Col>
				)
			})
		}
		</Row>
	)
}

export const getVehicleModelDataColumns = (onViewKit, can, companies) => [
	{
		title: i18n.t('VehicleModel.Make'),
		sorter: (a, b) => a.make.localeCompare(b.make),
		render: (e, r) => <div>{r.make}</div>
	},
	{
		title: i18n.t('VehicleModel.Model'),
		sorter: (a, b) => a.model.localeCompare(b.model),
		render: (e, r) => <div>{r.model}</div>
	},
	{
		title: i18n.t('VehicleModel.Year'),
		sorter: (a, b) => (a.yearOfManufacture?.toString() || "0").localeCompare(b.yearOfManufacture?.toString()  || "0"),
		render: (e, r) => <div>{r.yearOfManufacture}</div>
	},
	{
		title: i18n.t('VehicleModel.Company'),
		render: (e, r) => <div>{(companies || []).find(i=>i.id === r.company?.id)?.name || i18n.t('VehicleModel.GlobalModel')}</div>,
		responsive: ['lg'],
	},
	{
		title: i18n.t('VehicleModel.Source'),
		sorter: (a, b) => a.source?.localeCompare(b.source),
		render: (e, r) => <div>{`${r.source || "Unknown"} ${r.isDummyInternal ? "*" :""}`}</div>,
		responsive: ['md'],
	},
	{
		title: i18n.t('VehicleModel.Priority'),
		sorter: (a, b) => a.priority?.toString()?.localeCompare(b.priority?.toString()),
		render: (e, r) => <div>{`${r.priority || "-"}`}</div>,
		responsive: ['md'],
	},
	{
		title: i18n.t('VehicleModel.DefaultDbcKey'),
		sorter: (a, b) => a.dbcFileKey?.localeCompare(b.dbcFileKey),
		render: (e, r) => <div>{r.dbcFileKey}</div>,
		responsive: ['lg'],
	},
	{
		title: i18n.t('VehicleModel.Engine'),
		sorter: (a, b) => a.extras?.engine?.localeCompare(b.extras?.engine),
		render: (e, r) => <div>{r.extras?.engine}</div>
	},
	{
		title: i18n.t('VehicleModel.Universal Kit #'),
		render: (e, r) => {
			const kitDetail = r[KitType.UNIVERSAL_KIT];
			if (kitDetail && onViewKit){
				return renderKit(kitDetail, onViewKit, r)
			}
			else{
				return <div>{kitDetail?.sapCode || "-"}</div>
			}
		},
		responsive: ['xl'],
		align:"center",
		hidden: !can({ oneOfRole: [Role.ADMIN, Role.SITE_ADMIN] })
	},
	{
		title: i18n.t('VehicleModel.Kit #'),
		render: (e, r) => {
			const kitDetail = r[KitType.KIT];
			if (kitDetail && onViewKit){
				return renderKit(kitDetail, onViewKit, r)
			}
			else{
				return <div>{kitDetail?.sapCode || "-"}</div>
			}
		},
		responsive: ['xl'],
		align:"center",
		hidden: !can({ oneOfRole: [Role.ADMIN, Role.SITE_ADMIN] })
	},
	{
		title: i18n.t('VehicleModel.Y Cable (Included with Kit)'),
		render: (e, r) => {
			const kitDetail = r[KitType.Y_CABLE_KIT];
			
			if (kitDetail && onViewKit){
				return renderKit(kitDetail, onViewKit, r)
			}
			else{
				return <div>{kitDetail?.sapCode || "-"}</div>
			}
		},
		responsive: ['xl'],
		align:"center",
		hidden: !can({ oneOfRole: [Role.ADMIN, Role.SITE_ADMIN] })
	},
	{
		title: i18n.t('VehicleModel.Additional Parts'),
		render: (e, r) => {
			const kitDetail =r[KitType.ADDITIONAL];
			
			if (kitDetail && onViewKit) {
				return renderKit(kitDetail, onViewKit, r, false)
			}
			else{
				return <div>{kitDetail?.sapCode || "-"}</div>
			}
		},
		responsive: ['xl'],
		align:"center",
		hidden: !can({ oneOfRole: [Role.ADMIN, Role.SITE_ADMIN] })
	},
	{
		title: i18n.t('VehicleModel.Vendor'),
		sorter: (a, b) => a.deviceType?.localeCompare(b.deviceType),
		render: (e, r) => <div>{r.deviceType || "-"}</div>,
		responsive: ['lg'],
	},
	{
		title: i18n.t('VehicleModel.Supported'),
		responsive: ['xl'],
		align:"center",
		render: (e, r) => <div>{r.isSupported === undefined ? <QuestionCircleOutlined /> : (r.isSupported ? <CheckCircleOutlined style={{color:"green"}} /> : <CloseCircleOutlined style={{color:"red"}} />)}</div>
	},
	Table.EXPAND_COLUMN
].filter(item => !item.hidden);

export const VehicleMeasurementDataColumns = [
	{
		title: i18n.t('VehicleMeasurement.DeviceModel'),
		sorter: (a, b) => a.deviceModelName.localeCompare(b.deviceModelName),
		render: (e, r) => <div>{r.deviceModelName}</div>
	},
	{
		title: i18n.t('VehicleMeasurement.BusType'),
		sorter: (a, b) => a.busType.localeCompare(b.busType),
		render: (e, r) => <div>{r.busType}</div>
	},
	{
		title: i18n.t('VehicleMeasurement.Pid'),
		sorter: (a, b) => a.pid.localeCompare(b.pid),
		render: (e, r) => <div>{r.pid}</div>
	},
	{
		title: i18n.t('VehicleMeasurement.Description'),
		sorter: (a, b) => a.description.localeCompare(b.description),
		render: (e, r) => <div>{r.description}</div>
	},
	{
		title: i18n.t('VehicleMeasurement.MeasurementId'),
		render: (e, r) => <div>{r.extras?.id || "-"}</div>
	},
	{
		title: i18n.t('VehicleMeasurement.Unit'),
		render: (e, r) => <div>{r.extras?.unit || "-"}</div>
	},
	{
		title: i18n.t('VehicleMeasurement.ValueTable'),
		render: (e, r) => <div>{r.extras?.value || "-"}</div>
	},
	{
		title: i18n.t('VehicleMeasurement.Note'),
		render: (e, r) => <div>{r.extras?.note || "-"}</div>
	},
	{
		title: i18n.t('VehicleMeasurement.Category'),
		sorter: (a, b) => a.category.localeCompare(b.category),
		render: (e, r) => <div>{r.category}</div>
	}
];

export const getVehicleModelCleanupDataColumns = (action) => [
	{
		title: i18n.t('VehicleModel.Make'),
		sorter: (a, b) => a.make.localeCompare(b.make),
		render: (e, r) => <div>{r.make}</div>
	},
	{
		title: i18n.t('VehicleModel.Model'),
		sorter: (a, b) => a.model.localeCompare(b.model),
		render: (e, r) => <div>{r.model}</div>
	},
	{
		title: i18n.t('VehicleModel.Year'),
		sorter: (a, b) => (a.year?.toString() || "0").localeCompare(b.year?.toString()  || "0"),
		render: (e, r) => <div>{r.year}</div>
	},
	{
		title: i18n.t('VehicleModel.ExpectedMakeValue'),
		sorter: (a, b) => a.expectedMake.localeCompare(b.expectedMake),
		render: (e, r) => <div>{r.expectedMake}</div>,
		responsive: ['md']
	},
	{
		align:"center",
		render: (e, r) => {
			let actions = [
				{
					name: i18n.t('Audits.Audits'),
					onClick: () => {
						if (action.audit)
							action.audit(r)
					},
					permission: [Permission.AUDIT]
				},
				{
					name: i18n.t('VehicleModel.RenameMake'),
					onClick: () => {
						if (action.renameVehicleModel)
							action.renameVehicleModel(r)
					},
					permission: [Permission.COMMON_EDIT]
				},
				{
					name: i18n.t('VehicleModel.MarkVerified'),
					onClick: () => {
						if (action.markVerified)
							action.markVerified(r)
					},
					permission: [Permission.COMMON_EDIT]
				}
			];

			if ((r.groupId === undefined || r.groupId === null) && !r.isDummyInternal) {
				actions.push(
					{
						name: i18n.t('VehicleModel.Merge'),
						onClick: () => {
							if (action.mergeVehicleModel)
								action.mergeVehicleModel(r)
						},
						permission: [Permission.MERGE_VEHICLE_MODEL]
					});
			}
			return (<Can oneOfPermission={[Permission.COMMON_EDIT, Permission.MERGE_VEHICLE_MODEL]}>
				<Buttons
					noMargin={true}
					moreButtons={actions}
				/>
			</Can>
			)
		}
	}
];

export const getVehicleModelScanningRequestColumns = (action) => [
	{
		title: i18n.t('VehicleModel.Make'),
		sorter: (a, b) => a.vehicleModel.make.localeCompare(b.vehicleModel.make),
		render: (e, r) => <div>{r.vehicleModel.make}</div>
	},
	{
		title: i18n.t('VehicleModel.Model'),
		sorter: (a, b) => a.vehicleModel.model.localeCompare(b.vehicleModel.model),
		render: (e, r) => <div>{r.vehicleModel.model}</div>
	},
	{
		title: i18n.t('VehicleModel.Year'),
		sorter: (a, b) => (a.vehicleModel.yearOfManufacture?.toString() || "0").localeCompare(b.vehicleModel.yearOfManufacture?.toString()  || "0"),
		render: (e, r) => <div>{r.vehicleModel.yearOfManufacture}</div>
	},
	{
		title: i18n.t('VehicleModel.ScanningRequestCount'),
		sorter: (a, b) => (a.count.yearOfManufacture?.toString() || "0").localeCompare(b.count?.toString()  || "0"),
		render: (e, r) => <div>{r.count}</div>
	},
	{
		title: i18n.t('VehicleModel.ScanningRequestStatus'),
		sorter: (a, b) => a.status?.localeCompare(b.status),
		render: (e, r) => <Tag color={statusColors[r.status]}>{i18n.t('VehicleModel.RequestStatus.' + r.status)}</Tag>
	},
	{
		title: i18n.t('VehicleModel.Comment'),
		sorter: (a, b) => a.comment?.localeCompare(b.comment),
		render: (e, r) => <div>{r.comment}</div>,
		responsive: ['md']
	},
	{
		align:"center",
		render: (e, r) => {
			let actions = r.status === 'IN_REVIEW' ? [
				{
					name: i18n.t('VehicleModel.Approve'),
					onClick: () => {
						if (action.approve)
							action.approve(r)
					},
					permission: [Permission.COMMON_EDIT]
				},
				{
					name: i18n.t('VehicleModel.Decline'),
					onClick: () => {
						if (action.decline)
							action.decline(r)
					},
					permission: [Permission.COMMON_EDIT]
				},
				{
					name: i18n.t('Audits.Audits'),
					onClick: () => {
						if (action.audit)
							action.audit(r)
					},
					permission: [Permission.AUDIT]
				}
			] : [
				{
					name: i18n.t('Audits.Audits'),
					onClick: () => {
						if (action.audit)
							action.audit(r)
					},
					permission: [Permission.AUDIT]
				}
			];
			return (<Can oneOfPermission={[Permission.COMMON_EDIT]}>
				<Buttons
					noMargin={true}
					moreButtons={actions}
				/>
			</Can>);
		}
	}
];

export const getVehicleModelPollHistoryColumns = (action) => [
	{
		title: i18n.t('VehicleModel.Make'),
		sorter: (a, b) => a.vehicleModel.make.localeCompare(b.vehicleModel.make),
		render: (e, r) => <div>{r.vehicleModel.make}</div>
	},
	{
		title: i18n.t('VehicleModel.Model'),
		sorter: (a, b) => a.vehicleModel.model.localeCompare(b.vehicleModel.model),
		render: (e, r) => <div>{r.vehicleModel.model}</div>
	},
	{
		title: i18n.t('VehicleModel.Year'),
		sorter: (a, b) => (a.vehicleModel.yearOfManufacture?.toString() || "0").localeCompare(b.vehicleModel.yearOfManufacture?.toString()  || "0"),
		render: (e, r) => <div>{r.vehicleModel.yearOfManufacture}</div>
	},
	{
		title: i18n.t('VehicleModel.RequestedDate'),
		render: (e, r) => <div>{moment(r.createdAt).format("DD MMM YYYY HH:mm:ss")}</div>,
		responsive: ['md']
	},
	{
		title: i18n.t('VehicleModel.ScanningRequestStatus'),
		render: (e, r) => <Tag color={statusColors[r.status]}>{i18n.t('VehicleModel.RequestStatus.' + r.status)}</Tag>
	},
	{
		title: i18n.t('VehicleModel.Comment'),
		render: (e, r) => <div>{r.comment}</div>,
		responsive: ['md']
	},
	{
		align:"center",
		render: (e, r) => {
			let actions = [
				{
					name: i18n.t('VehicleModel.CancelRequest'),
					onClick: () => {
						if (action.cancelRequest)
							action.cancelRequest(r)
					}
				}
			];
			return r.status === 'IN_REVIEW' ? (
				<Buttons
					noMargin={true}
					moreButtons={actions}
				/>) : null;
		}
	}
];

export const getExportFilename = ({ entityName, extension }) => {
	if (!entityName) {
		return;
	}
	return `${entityName}${extension}`;
};

export const CsvSearchTemplateHeader = [
	[
		i18n.t("VehicleModel.Make"),
		i18n.t("VehicleModel.Model"),
		i18n.t("VehicleModel.Year")
	]
];