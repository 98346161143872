import React, {useState} from 'react';
import logoStyles from './sidebar-profile.module.scss';
import logo from "../../assets/css/default/image/logo.svg";
import logoCollapsed from "../../assets/css/default/image/logo_collapse.svg";
import {
	PushpinFilled
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive'

const SidebarProfile = ({isMainNavPinned, isMainNavExpanded, onChange}) =>{
	const { i18n, t } = useTranslation();
	const isMobile =useMediaQuery({ maxWidth: 767 })

	return (
		<ul style={{ padding: 0, paddingTop:5, marginTop:0 }}>
			<li className={logoStyles.pin}>
				<div className={logoStyles.LogoWordmark}>
					<img src={!isMainNavExpanded ? logoCollapsed : logo} />
					
				</div>
				<div onClick={e => {
						onChange(!isMainNavPinned);
					}}>
						{isMainNavExpanded && !isMobile && (
							<Tooltip title={isMainNavPinned ? t('Common.UnpinMenu') : t('Common.PinMenu')}>
								{isMainNavPinned ? (

									<PushpinFilled style={{color:"#86b341"}} rotate={-45}/>
								) : (
									<PushpinFilled style={{color:"#86b341"}} />
								)}
							</Tooltip>
						)}
					</div>
			</li>
		</ul>
	)
}

export default SidebarProfile;